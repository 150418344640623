import notFoundStyles from "./notFound.module.css";
import notFoundPic from "../../images/notFound.png";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <section className={notFoundStyles.section}>
      <h2 className={notFoundStyles.title}>אנחנו כאן בשבילך</h2>
      <p className={`${notFoundStyles.text} ${notFoundStyles.text_small}`}>
        אופס! נראה כאילו סטית מהשביל
      </p>
      <p className={notFoundStyles.text}>
        ההרגשה של לא לדעת איפה אנחנו נמצאים, יכולה להיות מטרידה{" "}
      </p>
      <p className={notFoundStyles.text}>אבל אתה לא לבד.</p>
      <p className={notFoundStyles.text}>
        בדיוק כמו שאנחנו תומכים ברווחת הנפש שלך, אנחנו כאן להדריך אותך לחזור
        לנתיב הנכון. בינתיים, תיקח נשימה עמוקה ותזכור שזה בסדר לטעות
      </p>
      <img
        src={notFoundPic}
        alt={"מספר 404 בחלל"}
        className={notFoundStyles.img}
      />
      <Link to={"/home"} className={`button hover ${notFoundStyles.link}`}>
        חזרה לדף הבית
      </Link>
      <p className={notFoundStyles.text}>
        אם נדרשת לך עזרה מיידית, אל תהסס לפנות
      </p>
      <article className={notFoundStyles.footer}>
        <h4 className={notFoundStyles.footerTitle}>שעות פעילות</h4>
        <p className={notFoundStyles.footerText}>
          א’ - ה’ בין השעות 16:00 - 09.00
        </p>
        <p className={notFoundStyles.footerText}>
          יום ו’ בין השעות 13:00 - 09:00
        </p>
        <p className={notFoundStyles.footerText}>
          פניות שיפתחו בשעות הפעילות יקבלו מענה עד סוף היום
        </p>
        <p className={notFoundStyles.footerText}>
          פניות שיפתחו לאחר שעות הפעילות, יענו עד סוף היום הבא
        </p>
        <p className={notFoundStyles.footerText}>
          במקרים דחופים חייגו : 075-5913596
        </p>
      </article>
    </section>
  );
}

export default NotFoundPage;
