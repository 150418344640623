import prevencherPageStyles from "./prevencherPage.module.css";
import venchy from "../../images/questionnaire_doctor.png";
import { prevencherCategoris } from "../../utils/categories";
import { useEffect, useState } from "react";
import PrevencherChat from "../../components/prevencherChat/prevencherChat";
import { useLocation } from "react-router-dom";
import { TLocationState } from "../../types/types";
import SideBarSmall from "../../components/sideBarSmall/sideBarSmall";
import PrevencherChatForGuests from "../../components/prevencherChat/prevencherChatForGuests";
import { getCookie } from "../../utils/token";
import PrevencherChatUrgent from "../../components/prevencherChat/prevencherChatUrgent";

function PrevencherPage() {
  const [category, setCategory] = useState("");
  const location = useLocation() as TLocationState;
  const token = getCookie("accessToken");
  useEffect(() => {
    if (location.state?.pathname.includes("chat")) {
      setCategory("chat");
    }
  }, []);

  return (
    <div className={prevencherPageStyles.section}>
      <div className={prevencherPageStyles.content}>
        {!category ? (
          <>
            <img
              src={venchy}
              className={prevencherPageStyles.venchy}
              alt={"תמונה של וונצ’י"}
            />
            <ul className={prevencherPageStyles.tabs}>
              {prevencherCategoris.map((cat, index) => (
                <li key={index} className={`${prevencherPageStyles.tab} hover`}>
                  <button
                    className={prevencherPageStyles.tabBtn}
                    onClick={() => setCategory(cat.value)}
                  >
                    {cat.title}
                  </button>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <ul className={prevencherPageStyles.tabs_horizontal}>
            {prevencherCategoris.map((cat, index) => (
              <li key={index} className={`${prevencherPageStyles.tab} hover`}>
                <button
                  className={`${prevencherPageStyles.tabBtn} ${
                    prevencherPageStyles.tabBtn_horizontal
                  } ${
                    cat.value === category && prevencherPageStyles.tabBtn_active
                  }`}
                  onClick={() => setCategory(cat.value)}
                >
                  <img
                    src={cat.img}
                    alt={cat.title}
                    className={prevencherPageStyles.btnImg}
                  />
                  {cat.title}
                </button>
              </li>
            ))}
          </ul>
        )}
        {category === "chat" &&
          (token === "guestToken" ? (
            <PrevencherChatForGuests />
          ) : location.state?.pathname === "chatGetHelp" ? (
            <PrevencherChatUrgent />
          ) : (
            <PrevencherChat />
          ))}
      </div>
      <SideBarSmall />
    </div>
  );
}

export default PrevencherPage;
