import onboardingStyles from "./onboardingLayout.module.css";
import { FC, ReactNode } from "react";
import logo from "../../images/logo.png";

interface IOnboardingLayoutProps {
  children: ReactNode;
}

const OnboardingLayout: FC<IOnboardingLayoutProps> = ({ children }) => {
  return (
    <section className={`${onboardingStyles.content}`}>
      <img src={logo} alt={"לוגו"} className={onboardingStyles.logo} />
      <main className={onboardingStyles.main}>{children}</main>
    </section>
  );
};

export default OnboardingLayout;
