import styles from "./myReports.module.css";
import { useEffect, useState } from "react";
import { reportsCategories } from "../../utils/categories";
import BackArrowButton from "../backArrowButton/backArrowButton";
import {
  myReportsPeriodExample,
  myReportsSummaryExample,
} from "../../utils/constants";
import { IReport } from "../../types/types";
import { getPrintDate } from "../../utils/utils";
import AddNewReport from "../addNewReport/addNewReport";

function MyReports() {
  const [category, setCategory] = useState("");
  const [reportsToShow, setReportsToShow] = useState<IReport[] | null>(null);
  const [showCreateNewReportComponent, setShowCreateNewReportComponent] =
    useState(false);

  useEffect(() => {
    if (category === "period") {
      setReportsToShow(myReportsPeriodExample);
    } else if (category === "summary") {
      setReportsToShow(myReportsSummaryExample);
    } else setReportsToShow(null);
  }, [category]);
  return (
    <section className={styles.section}>
      <h2 className={styles.sectionTitle}>דוחות ההתקדמות שלי</h2>
      {category && (
        <BackArrowButton
          onClick={() => {
            setCategory("");
            setShowCreateNewReportComponent(false);
          }}
          position={"center"}
        />
      )}
      {showCreateNewReportComponent ? (
        <AddNewReport />
      ) : category ? (
        <article className={styles.reportsContent}>
          <ul className={styles.reportsList}>
            {reportsToShow &&
              reportsToShow.map((r) => (
                <li key={r.id} className={styles.reportCard}>
                  <p className={styles.reportCard__date}>
                    {getPrintDate(r.date)}
                  </p>
                  <p className={styles.reportCard__title}>{r.title}</p>
                  <p className={styles.reportCard__title}>{r.subTitle}</p>
                </li>
              ))}
          </ul>
          <button
            type={"button"}
            className={`button ${styles.reportsCreateBtn} hover`}
            onClick={() => setShowCreateNewReportComponent(true)}
          >
            {category === "summary"
              ? "יצירת סיכום פגישה"
              : "יצירת דו״ח התקדמות חדש"}
          </button>
        </article>
      ) : (
        <ul className={styles.reports}>
          {reportsCategories.map((r, index) => (
            <li key={index} className={styles.reportsLi}>
              <button
                type={"button"}
                onClick={() => setCategory(r.value)}
                className={`${styles.reportsBtn} hover`}
              >
                {r.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}

export default MyReports;
