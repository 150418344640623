import contactUsStyles from "./contactUs.module.css";
import onlineSupportIcon from "../../images/online_support_icon.svg";
import techSupportIcon from "../../images/tech_support_icon.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { IContactUsTheme } from "../../utils/categories";
import FaqItem from "../faqItem/faqItem";
import {
  auxiliaryWords,
  contactUsList,
  ContactUsSearchOptions,
  customerService,
} from "../../utils/constants";
import PrevencherAnswer from "../prevencherAnswer/prevencherAnswer";
import { useNavigate } from "react-router-dom";

function ContactUs() {
  const navigate = useNavigate();

  const [searchOption, setSearchOption] = useState<ContactUsSearchOptions>(
    ContactUsSearchOptions.Input
  );
  const [value, setValue] = useState<string>("");
  const [toggle, setToggle] = useState({});
  const [variants, setVariants] = useState<IContactUsTheme[]>([]);
  const [showThemes, setShowThemes] = useState(false);

  useEffect(() => {
    if (!value.length) {
      setVariants([]);
    } else {
      const words = value
        .split(" ")
        .filter((word) => !auxiliaryWords.includes(word));
      setVariants(
        contactUsList.filter((d) => {
          for (let i = 0; i < words.length; i++) {
            if (d.text.includes(words[i]) || d.title.includes(words[i])) {
              return d;
            }
          }
        })
      );
    }
  }, [value]);

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchOption(ContactUsSearchOptions.Input);
    setShowThemes(false);
    setValue(event.target.value);
  };

  return (
    <section className={contactUsStyles.section}>
      <h2 className={contactUsStyles.section__title}>חפשו פתרון כאן</h2>
      <ul className={contactUsStyles.searchOptions}>
        <li
          className={`${contactUsStyles.searchOption} ${
            searchOption === ContactUsSearchOptions.Input &&
            contactUsStyles.searchOption_active
          }`}
          onClick={() => setSearchOption(ContactUsSearchOptions.Input)}
        >
          איך נוכל לעזור לך?
        </li>
        <li
          className={`${contactUsStyles.searchOption} ${
            searchOption === ContactUsSearchOptions.List &&
            contactUsStyles.searchOption_active
          }`}
          onClick={() => {
            setShowThemes(false);
            setVariants([]);
            setValue("");
            setSearchOption(ContactUsSearchOptions.List);
          }}
        >
          לרשימת הנושאים
        </li>
      </ul>
      <input
        type={"text"}
        name={"search"}
        id={"search"}
        onChange={onChangeValue}
        value={value}
        className={contactUsStyles.input}
      />
      {variants.length > 0 && !showThemes && (
        <ul className={contactUsStyles.variants}>
          {variants.map((v, index) => (
            <li
              key={index}
              className={contactUsStyles.variant}
              onClick={() => {
                setValue(v.title);
                setShowThemes(true);
              }}
            >
              {v.title}
            </li>
          ))}
        </ul>
      )}
      {showThemes && (
        <>
          <ul
            className={`${contactUsStyles.questionList} ${contactUsStyles.questionList_prevencher}`}
          >
            {variants.map((theme, index) => (
              <PrevencherAnswer key={index} theme={theme} />
            ))}
          </ul>
        </>
      )}
      {searchOption === ContactUsSearchOptions.Input ? (
        <>
          <p className={contactUsStyles.text}>
            אם לא מצאת פתרון, פתחי פנייה ונשמח לעזור לך
          </p>
          <ul className={contactUsStyles.contactOptions}>
            <li className={`${contactUsStyles.contactOption} hover`}>
              <h3 className={contactUsStyles.contactOption__title}>
                תמיכה טכנית
              </h3>
              <img
                className={contactUsStyles.contactOption__img}
                src={techSupportIcon}
                alt={"תמיכה טכנית"}
              />
              <button
                className={`${contactUsStyles.contactOption__btn} hover`}
                onClick={() => {
                  navigate("/profile", {
                    replace: true,
                    state: { pathname: "techSupport" },
                  });
                }}
              >
                לפתיחת פנייה
              </button>
            </li>
            <li className={`${contactUsStyles.contactOption} hover`}>
              <h3 className={contactUsStyles.contactOption__title}>
                שירות לקוחות
              </h3>
              <img
                className={contactUsStyles.contactOption__img}
                src={onlineSupportIcon}
                alt={"שירות לקוחות"}
              />
              <button
                className={`${contactUsStyles.contactOption__btn} hover`}
                onClick={() => {
                  navigate("/profile", {
                    replace: true,
                    state: { pathname: customerService },
                  });
                }}
              >
                לפתיחת פנייה
              </button>
            </li>
          </ul>
        </>
      ) : (
        <>
          <h3
            className={`${contactUsStyles.section__title} ${contactUsStyles.section__title_list}`}
          >
            רשימת הנושאים
          </h3>
          <ul className={contactUsStyles.questionList}>
            {contactUsList.map((theme, index) => (
              <FaqItem
                key={index}
                theme={theme}
                index={index}
                toggle={toggle}
                toggleAnswer={toggleAnswer}
                setToggle={setToggle}
              />
            ))}
          </ul>
        </>
      )}
      <article className={contactUsStyles.section__footer}>
        <h4 className={contactUsStyles.footerTitle}>שעות פעילות</h4>
        <p className={contactUsStyles.footerText}>
          א’ - ה’ בין השעות 16:00 - 09.00
        </p>
        <p className={contactUsStyles.footerText}>
          יום ו’ בין השעות 13:00 - 09:00
        </p>
        <p className={contactUsStyles.footerText}>
          פניות שיפתחו בשעות הפעילות יקבלו מענה עד סוף היום
        </p>
        <p className={contactUsStyles.footerText}>
          פניות שיפתחו לאחר שעות הפעילות, יענו עד סוף היום הבא
        </p>
        <p className={contactUsStyles.footerText}>
          במקרים דחופים חייגו : 075-5913596
        </p>
      </article>
    </section>
  );
}

export default ContactUs;
