import myCardStyles from "./myCard.module.css";
import React, { useEffect, useState } from "react";
import UpdateCardForm from "../forms/updateCard/updateCardForm";
import myCardImg from "../../images/myCardImg.png";
import { useLocation } from "react-router-dom";
import { TLocationState } from "../../types/types";

function MyCard() {
  const [showForm, setShowForm] = useState(false);

  const location = useLocation() as TLocationState;

  useEffect(() => {
    if (location.state?.pathname === "updateCard") {
      setShowForm(true);
    }
  });

  return showForm ? (
    <UpdateCardForm />
  ) : (
    <section className={myCardStyles.section}>
      <h2 className={myCardStyles.section__title}>עדכון פרטי אשראי</h2>
      <img src={myCardImg} alt={""} className={myCardStyles.section__img} />
      <p className={myCardStyles.text}>אמצעי תשלום לא הוגדר</p>
      <button
        className={`button hover ${myCardStyles.btn}`}
        type="button"
        onClick={() => setShowForm(true)}
      >
        הגדרת פרטי אשראי
      </button>
    </section>
  );
}

export default MyCard;
