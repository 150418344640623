import forumStyles from "./forumPage.module.css";
import { forumExample, forumThemes } from "../../utils/constants";
import { ChangeEvent, useState } from "react";
import messagesIcon from "../../images/forum_messages_icon.svg";
import SideBarSmall from "../../components/sideBarSmall/sideBarSmall";
import { getDate, getTimeFromTimestamp } from "../../utils/utils";
import CreateForumTheme from "../../components/createForumTheme/createForumTheme";
import ForumThread from "../../components/forumThread/forumThread";

function ForumPage() {
  const [active, setActive] = useState<string>(forumThemes[0]);
  const [value, setValue] = useState<string>("");
  const [showCreateNewTheme, setShowCreateNewTheme] = useState<boolean>(false);
  const [thread, setThread] = useState("");

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <section className={forumStyles.section}>
      <div className={`${forumStyles.content} scroll_violet`}>
        {thread ? (
          <ForumThread />
        ) : !showCreateNewTheme ? (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setValue("");
              }}
            >
              <input
                name={"forumSearch"}
                type={"text"}
                className={forumStyles.input}
                value={value}
                onChange={onChangeValue}
              />
            </form>
            <ul className={forumStyles.themes}>
              {forumThemes.map((t, index) => (
                <li
                  key={index}
                  onClick={() => setActive(t)}
                  className={`${forumStyles.themesLi} ${
                    t === active && forumStyles.themeLi_active
                  }`}
                >
                  {t}
                </li>
              ))}
            </ul>
            <button
              type={"button"}
              className={`${forumStyles.btn} hover`}
              onClick={() => {
                setShowCreateNewTheme(true);
              }}
            >
              <span className={forumStyles.plus}>+</span>
              יצירת שרשור חדש
            </button>
            <ul className={forumStyles.forumList}>
              {forumExample.map((m, index) => (
                <li
                  key={index}
                  className={forumStyles.forumLi}
                  onClick={() => setThread(m.id)}
                >
                  <article className={forumStyles.card}>
                    <h3 className={forumStyles.card__title}>{m.title}</h3>
                    <div className={forumStyles.card__user}>
                      <img
                        className={forumStyles.card__avatar}
                        src={m.avatar}
                        alt={m.userName}
                      />
                      <div className={forumStyles.card__data}>
                        <p className={forumStyles.card__name}>{m.userName}</p>
                        <p className={forumStyles.card__date}>
                          {`${getDate(
                            m.date.toString()
                          )}, ${getTimeFromTimestamp(m.date)}`}
                        </p>
                      </div>
                    </div>
                    <p className={forumStyles.text}>{m.lastMessage}</p>
                    <p className={forumStyles.messages}>
                      <img
                        src={messagesIcon}
                        alt={"סמל תגובות"}
                        className={forumStyles.messagesIcon}
                      />
                      {`${m.messages} תגובות `}
                    </p>
                  </article>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <CreateForumTheme
            setShowCreateNewTheme={() => setShowCreateNewTheme(false)}
          />
        )}
      </div>
      <SideBarSmall />
    </section>
  );
}

export default ForumPage;
