import chatSidebarStyles from "./chatSidebar.module.css";
import { ITherapist } from "../../types/types";
import { Dispatch, SetStateAction } from "react";

function ChatSideBar({
  allTherapists,
  setTherapist,
  setOpenSideBar,
  therapist,
  openMenu,
}: {
  allTherapists: Partial<ITherapist>[];
  setTherapist: (therapist: Partial<ITherapist>) => void;
  setOpenSideBar?: Dispatch<SetStateAction<boolean>>;
  therapist: Partial<ITherapist>;
  openMenu: boolean;
}) {
  const getUnreadMessages = (therapist: Partial<ITherapist>) => {
    return therapist.messages?.filter((m) => m.unread).length;
  };
  return (
    <section
      className={`${chatSidebarStyles.sidebar} ${
        !openMenu && chatSidebarStyles.sidebar_closed
      }`}
    >
      <div className={chatSidebarStyles.sidebar__buttons}>
        <button className={chatSidebarStyles.sidebar__btn}>ארכיון</button>
        <button
          className={`${chatSidebarStyles.sidebar__btn} ${chatSidebarStyles.sidebar__btn_active}`}
        >
          כל השיחות
        </button>
      </div>
      <input
        type="text"
        placeholder="חיפוש"
        className={chatSidebarStyles.sidebar__input}
      />
      <ul
        className={`${chatSidebarStyles.sidebar__therapists} ${chatSidebarStyles.scrollable}`}
      >
        {allTherapists.map((t, index) => (
          <li
            key={index}
            className={`${chatSidebarStyles.sidebar__li} ${
              t.id === therapist.id && chatSidebarStyles.sidebar__li_active
            }`}
            onClick={(e) => {
              setTherapist(t);
              if (setOpenSideBar) setOpenSideBar(false);
            }}
          >
            <div
              className={`${chatSidebarStyles.sidebar__online} ${
                t.online ? chatSidebarStyles.sidebar__online_active : ""
              }`}
            >
              <img
                className={chatSidebarStyles.sidebar__avatar}
                src={t.avatar}
                alt={t.name}
              />
            </div>
            <div className={chatSidebarStyles.sidebar__therapist}>
              <p className={chatSidebarStyles.sidebar__name}>{t.name}</p>
              <div className={chatSidebarStyles.sidebar__therapistInfo}>
                <p className={chatSidebarStyles.sidebar__status}>{t.status}</p>
                {t.tag && (
                  <p className={chatSidebarStyles.sidebar__tag}>{t.tag}</p>
                )}
              </div>
            </div>
            {getUnreadMessages(t) && (
              <p className={chatSidebarStyles.sidebar__unread}>
                {getUnreadMessages(t)}
              </p>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ChatSideBar;
