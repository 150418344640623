import { RootState } from "../types";

export const userSelector = (state: RootState) => state.user.user;
export const userPlanSelector = (state: RootState) => state.user.user.planId;
export const userAppointmentsSelector = (state: RootState) =>
  state.user.user.appointments;
export const userTherapistsSelector = (state: RootState) =>
  state.user.user.therapists;
export const allflowUserSelector = (state: RootState) =>
  state.utils.allUserFlows;
export const currentUserFlowIdSelector = (state: RootState) =>
  state.user.currentUserFlowId;
export const existingUserFlowIdSelector = (state: RootState) =>
  state.utils.existingUserFlowId;
export const guestUserFlowIdSelector = (state: RootState) =>
  state.utils.guestUserFlowId;
export const nextStepSelector = (state: RootState) => state.user.nextStep;
export const questionnaireIdSelector = (state: RootState) =>
  state.user.currentQuestionnaireId;
export const nextQuestionIdSelector = (state: RootState) =>
  state.user.nextQuestionId;
export const isEndSelector = (state: RootState) => state.user.isEnd;
export const currentQuestionSelector = (state: RootState) =>
  state.user.currentQuestion;
export const currentQuestionIdSelector = (state: RootState) =>
  state.user.currentQuestionId;
export const questionsOptionsSelector = (state: RootState) =>
  state.user.questionsOptions;
export const prevencherChatMessagesSelector = (state: RootState) =>
  state.user.prevencherChatMessages;
export const prevencherPOCMessagesSelector = (state: RootState) =>
  state.user.prevencherPOCMessages;
export const endQuestionSelector = (state: RootState) => state.user.endQuestion;
