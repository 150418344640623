import { createSlice } from "@reduxjs/toolkit";
import { IUserGoal, TUserGoalsState } from "../types/goals.types";
import { initialGoalsVariants } from "../../utils/constants";

const initialState: TUserGoalsState = {
  goals: initialGoalsVariants,
};

export const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    setGoals: (state, { payload }) => {
      const newGoals: IUserGoal[] = payload.map((g: string) => {
        return {
          title: g,
          progress: 60,
          comment: "מצוין! הלכת פעמיים השבוע למכון הכושר, המשך ככה!",
        };
      });
      return {
        ...state,
        goals: newGoals,
      };
    },
  },
});

export const { actions, reducer } = goalsSlice;
export default goalsSlice.reducer;
