import { useFormik } from "formik";
import signinFormStyles from "./signinForms.module.css";
import { ISigninFormProps } from "../../../types/signinTypes";
import { signUpValidationSchema1 } from "../../../utils/validation";
import React from "react";

function SigninForm1({ state, setState, changeForm }: ISigninFormProps) {
  const formik = useFormik({
    initialValues: {
      firstName: state.firstName,
      lastName: state.lastName,
      passport: state.passport,
      email: state.email,
    },
    validationSchema: signUpValidationSchema1,
    onSubmit: (values) => {
      // Handle form submission
      setState({
        ...state,
        firstName: values.firstName,
        lastName: values.lastName,
        passport: values.passport,
        email: values.email,
      });
      changeForm();
    },
  });

  return (
    <form
      className={signinFormStyles.signin_private__form}
      onSubmit={formik.handleSubmit}
    >
      <label htmlFor="name" className={signinFormStyles.signin_private__label}>
        שם פרטי
      </label>
      <input
        type="text"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.firstName &&
          signinFormStyles.signin_private__input_invalid
        }`}
        name="firstName"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="שם פרטי"
      />
      <label
        htmlFor="lastName"
        className={signinFormStyles.signin_private__label}
      >
        שם משפחה
      </label>
      <input
        type="text"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.lastName &&
          signinFormStyles.signin_private__input_invalid
        }`}
        name="lastName"
        id="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="שם משפחה"
      />
      <label
        htmlFor="passport"
        className={signinFormStyles.signin_private__label}
      >
        תעודת זהות
      </label>
      <input
        type="number"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.passport &&
          signinFormStyles.signin_private__input_invalid
        }`}
        name="passport"
        minLength={9}
        value={formik.values.passport}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="תעודת זהות"
      />
      <label htmlFor="email" className={signinFormStyles.signin_private__label}>
        דואר אלקטרוני
      </label>
      <input
        type="email"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.email && signinFormStyles.signin_private__input_invalid
        }`}
        name="email"
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="דואר אלקטרוני"
        required={true}
      />
      {formik.errors && (
        <p className={`${signinFormStyles.signin_private__invalid_message}`}>
          {formik.errors.firstName ??
            formik.errors.lastName ??
            formik.errors.passport ??
            formik.errors.email}
        </p>
      )}
      <button
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className={`${signinFormStyles.signin_private__btn} ${
          !formik.isValid || formik.isSubmitting
            ? signinFormStyles.signin_private__btn_disabled
            : "hover"
        }`}
      >
        המשך
      </button>
    </form>
  );
}

export default SigninForm1;
