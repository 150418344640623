import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { INACTIVITY_TIMEOUT, WARNING_TIMEOUT } from "../utils/constants";
import { useActions } from "../services/hooks/useActions";

const useInactivityTimer = (
  setShowWarning: Dispatch<SetStateAction<boolean>>
) => {
  const navigate = useNavigate();
  const { logout } = useActions();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let warningTimer: NodeJS.Timeout;

    const resetTimers = () => {
      clearTimeout(timer);
      clearTimeout(warningTimer);
      setShowWarning(false);

      warningTimer = setTimeout(() => {
        setShowWarning(true);
      }, WARNING_TIMEOUT);

      timer = setTimeout(() => {
        logout();
        setShowWarning(false);
        navigate("/");
      }, INACTIVITY_TIMEOUT);
    };

    const handleActivity = () => {
      resetTimers();
    };

    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);

    resetTimers(); // Initialize timers

    return () => {
      clearTimeout(timer);
      clearTimeout(warningTimer);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, []);
};

export default useInactivityTimer;
