import { api } from "./api";
import {
  IRequestNumberResponse,
  TSendCouponRequest,
  TSupportRequest,
} from "../types/requests.types";

export const requestsApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendCoupon: build.mutation<IRequestNumberResponse, TSendCouponRequest>({
      query: ({ coupon, id }) => ({
        url: `/coupon/${id}`,
        method: "POST",
        coupon,
      }),
    }),
    sendTechSupportRequest: build.mutation<
      IRequestNumberResponse,
      TSupportRequest
    >({
      query: ({ request }) => ({
        url: `support`,
        method: "POST",
        body: request,
        request,
      }),
    }),
    sendCustomerServiceRequest: build.mutation<
      IRequestNumberResponse,
      TSupportRequest
    >({
      query: ({ request }) => ({
        url: `customerService`,
        method: "POST",
        body: request,
        request,
      }),
    }),
  }),
});

export const {
  useSendCouponMutation,
  useSendTechSupportRequestMutation,
  useSendCustomerServiceRequestMutation,
} = requestsApi;
