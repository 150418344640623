import prevencherChatStyles from "./prevencherChat.module.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  RegisterRequestKeys,
  TMessageWithNextQuestionId,
  TPrevencherChatForGuestsOption,
  TPrevencherForGuestsQuestion,
} from "../../types/types";
import Message from "../message/message";
import {
  existingUserFlowId,
  phoneNumber,
  venchyData,
} from "../../utils/constants";
import {
  prevencherId,
  signupQuestions,
  startQuestions,
  urgentQuestions,
} from "../../utils/prevencherForGuestsQuestions";
import { IRegisterRequest } from "../../services/types/auth.types";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signUpValidationSchemaFull } from "../../utils/validation";
import { useRegisterMutation } from "../../services/api/auth.api";
import Modal from "../modals/modal/Modal";
import SignupSuccessPopup from "../modals/signup_success_popup/signup_success_popup";

function PrevencherChatForGuests() {
  const navigate = useNavigate();
  const lastElRef = useRef<HTMLDivElement | null>(null);
  const guestUserId = "0";

  const [index, setIndex] = useState(0);
  const [messages, setMessages] = useState<TMessageWithNextQuestionId[]>([]);
  const [options, setOptions] = useState<TPrevencherChatForGuestsOption[]>([]);
  const [questionnaire, setQuestionnaire] = useState<
    TPrevencherForGuestsQuestion[] | null
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);

  const [register, { isLoading }] = useRegisterMutation();

  const formik = useFormik({
    initialValues: {
      usersFlowId: existingUserFlowId,
      firstName: "",
      lastName: "",
      passport: "",
      birth: "",
      phone: "",
      email: "",
      password: "",
      genderId: -1,
      country: "",
    } as IRegisterRequest,
    validationSchema: signUpValidationSchemaFull,
    onSubmit: async (values: IRegisterRequest) => {
      try {
        const response = await register(values);
        if ("data" in response && response.data.success) {
          setMessages((prev) => [
            ...prev,
            {
              messageId: response.toString(),
              id: "0",
              nextQuestionId: -1,
              message: response.data?.message || "משתמש נוצר בהצלחה",
              userId: prevencherId,
              unread: false,
              date: new Date(),
            },
          ]);
          setOpenModal(true);
        } else {
          let errorMessage: string;

          if ("error" in response && response.error) {
            if ("status" in response.error && response.error?.status === 409) {
              errorMessage =
                "משתמש קיים. אנא התחבר עם הדואר האלקטרוני או מספר הטלפון שלך";
            } else {
              if (
                "data" in response.error &&
                response.error.data &&
                // @ts-ignore
                "message" in response.error.data
              ) {
                // @ts-ignore
                errorMessage = response.error.data.message;
              } else {
                errorMessage = "משהו השתבש, אנא נסה שוב מאוחר יותר";
              }
            }
            setMessages((prev) => [
              ...prev,
              {
                messageId: response.toString(),
                id: "0",
                nextQuestionId: -1,
                message: errorMessage,
                userId: prevencherId,
                unread: false,
                date: new Date(),
              },
            ]);
          }
        }
      } catch (error) {
        console.error("An error occurred during registration:", error);
      }
    },
  });

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onStartQuestionClick = (option: TPrevencherChatForGuestsOption) => {
    setQuestionnaire(option.id === 0 ? signupQuestions : urgentQuestions);
    if (option.id === 1) setIsUrgent(true);
    setMessages((prev) => [
      ...prev,
      {
        messageId: option.desc,
        id: index.toString(),
        date: new Date(),
        message: option.desc,
        userId: guestUserId,
        unread: false,
        nextQuestionId: startQuestions[0].nextQuestionId,
      },
      {
        messageId: index.toString(),
        id: index.toString(),
        date: new Date(),
        message:
          option.id === 0
            ? signupQuestions[index].message
            : urgentQuestions[index].message,
        userId: prevencherId,
        unread: false,
        nextQuestionId:
          option.id === 0
            ? signupQuestions[index].nextQuestionId
            : urgentQuestions[index].nextQuestionId,
      },
    ]);
    setOptions(
      option.id === 0
        ? signupQuestions[index].options
        : urgentQuestions[index].options
    );
  };
  const onOptionClick = (option: TPrevencherChatForGuestsOption) => {
    if (!questionnaire) return;
    const currentMessage = questionnaire[index];
    const nextMessage = questionnaire[currentMessage.nextQuestionId];

    if (nextMessage?.dataType === "options") {
      setOptions(nextMessage.options);
    }
    if (currentMessage.id === "signup" && option.value) {
      option.value === "gender"
        ? formik.setFieldValue("genderId", option.id)
        : formik.setFieldValue(option.value, option.desc);
    }
    if (currentMessage.id === "urgent") {
      if (option?.next === "signup") {
        setQuestionnaire(signupQuestions);
        setIndex(0);
        setMessages((prev) => [
          ...prev,
          {
            messageId: option.desc,
            id: "0",
            nextQuestionId: 0,
            message: option.desc,
            userId: guestUserId,
            unread: false,
            date: new Date(),
          },
          {
            messageId: index.toString(),
            id: "0",
            nextQuestionId: signupQuestions[0].nextQuestionId,
            message: signupQuestions[0].message,
            userId: prevencherId,
            unread: false,
            date: new Date(),
          },
        ]);
        return;
      }
    }
    setIndex(currentMessage.nextQuestionId);

    setMessages((prev) => [
      ...prev,
      {
        messageId: option.desc,
        id: "0",
        nextQuestionId: currentMessage.nextQuestionId,
        message: option.desc,
        userId: guestUserId,
        unread: false,
        date: new Date(),
      },
      {
        messageId: index.toString(),
        id: "0",
        nextQuestionId: nextMessage?.nextQuestionId,
        message: nextMessage?.message || "מצוין",
        userId: prevencherId,
        unread: false,
        date: new Date(),
      },
    ]);
  };

  const onInputSubmit = (inputName: RegisterRequestKeys) => {
    if (!questionnaire) return;
    formik.validateForm().then((res) => {
      if (inputName in res) {
        setMessages((prev) => [
          ...prev,
          {
            messageId: `${inputName}${index}`,
            id: index.toString(),
            date: new Date(),
            message: res[inputName] as string,
            userId: prevencherId,
            unread: false,
            nextQuestionId: questionnaire[index].nextQuestionId,
          },
        ]);
      } else {
        const currentMessage = questionnaire[index];
        const nextMessage = questionnaire[currentMessage.nextQuestionId];
        if (inputName === "password") {
          formik.submitForm();
        }
        setMessages((prev) => [
          ...prev,
          {
            messageId: currentMessage.messageId,
            id: index.toString(),
            date: new Date(),
            message: formik.values[inputName].toString(),
            userId: guestUserId,
            unread: false,
            nextQuestionId: questionnaire[index].nextQuestionId,
          },
          {
            messageId: index.toString(),
            id: index.toString(),
            date: new Date(),
            message: nextMessage.message,
            userId: prevencherId,
            unread: false,
            nextQuestionId: nextMessage.nextQuestionId,
          },
        ]);
        if (nextMessage?.dataType === "options") {
          setOptions(nextMessage.options);
        }
        setIndex(questionnaire[index].nextQuestionId);
      }
    });
  };
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, inputId: string) => {
      if (event.key === "Enter") {
        onInputSubmit(inputId);
      }
    },
    [onInputSubmit]
  );

  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  const closeModal = () => {
    navigate("/prevencher", {
      state: {
        pathname: isUrgent ? "isUrgent" : "chatSignin",
      },
    });
    setOpenModal(false);
  };
  return (
    <div className={`${prevencherChatStyles.section}`}>
      <Message
        message={startQuestions[0]}
        isOwnMessage={false}
        therapist={venchyData}
      />
      <ul className={prevencherChatStyles.variants}>
        {!questionnaire &&
          startQuestions[index]?.options.map((o, index) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={index}
              onClick={() => onStartQuestionClick(o)}
            >
              <span>{o.desc}</span>
            </li>
          ))}
      </ul>
      {messages.length > 0 &&
        messages.map((message, index) => (
          <Message
            key={index}
            isOwnMessage={message.userId === guestUserId}
            therapist={venchyData}
            message={message}
          />
        ))}
      {questionnaire &&
        questionnaire[index] &&
        (questionnaire[index].dataType === "options" ? (
          <ul className={prevencherChatStyles.variants}>
            {options.map((o, index) => (
              <li
                className={`${prevencherChatStyles.variant}  hover`}
                key={index}
                onClick={() => onOptionClick(o)}
              >
                {o.link ? (
                  <Link to={o.link} className={prevencherChatStyles.link}>
                    פורום
                  </Link>
                ) : o.input ? (
                  <a
                    href={`tel:${phoneNumber}`}
                    role={"link"}
                    className={prevencherChatStyles.link}
                  >
                    {phoneNumber}
                  </a>
                ) : (
                  <span>{o.desc}</span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className={prevencherChatStyles.inputContainer}>
            <input
              type={questionnaire[index]?.inputType}
              autoFocus={true}
              required={true}
              name={questionnaire[index]?.inputId as string}
              className={`${prevencherChatStyles.variant}  ${
                prevencherChatStyles.input
              } ${
                questionnaire[index] &&
                formik.touched[questionnaire[index].inputId as string] &&
                formik.errors[questionnaire[index]?.inputId as string] &&
                prevencherChatStyles.inputContainer_inValid
              }`}
              value={
                formik.values[
                  questionnaire[index].inputId as keyof IRegisterRequest
                ]
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(event) =>
                handleKeyDown(event, questionnaire[index].inputId as string)
              }
            />
            <button
              type={"submit"}
              className={prevencherChatStyles.sendBtn}
              onClick={(e) => {
                e.preventDefault();
                onInputSubmit(
                  questionnaire[index].inputId as keyof IRegisterRequest
                );
              }}
            />
          </div>
        ))}
      <div ref={lastElRef} />
      {openModal && (
        <Modal onClose={closeModal}>
          <SignupSuccessPopup onClose={closeModal} />
        </Modal>
      )}
    </div>
  );
}

export default PrevencherChatForGuests;
