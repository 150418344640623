import makeAppointmentStyles from "./makeAppointment.module.css";
import { ITherapist } from "../../types/types";
import Calendar from "react-calendar";
import React, { useEffect, useMemo, useState } from "react";
import { appointmentOptions, week } from "../../utils/constants";
import Modal from "../modals/modal/Modal";
import AppointmentSuccessPopup from "../modals/appointment_success_popup/appointment_success_popup";
import { useAppSelector } from "../../services/hooks";
import { availableDatesSelector } from "../../services/selectors/therapists.selector";
import {
  useMakeAppointmentMutation,
  useUpdateAppointmentMutation,
} from "../../services/api/user.api";
import { useActions } from "../../services/hooks/useActions";
import BackArrowButton from "../backArrowButton/backArrowButton";
import { TUpdateAppointmentRequest } from "../../services/types/user.types";

interface IMakeAppointmentProps {
  therapist: ITherapist;
  onClose?: () => void;
  appointmentId?: null | number;
  resetAppointmentId?: () => void;
}

function MakeAppointment({
  therapist,
  onClose,
  appointmentId,
  resetAppointmentId,
}: IMakeAppointmentProps) {
  const availableDates = useAppSelector(availableDatesSelector);
  const [activeDate, setActiveDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [changeOption, setChangeOption] = useState(appointmentOptions.Create);

  const [makeAppointment, { isError }] = useMakeAppointmentMutation();
  const [updateAppointment, { isError: isUpdateAppointmentError }] =
    useUpdateAppointmentMutation();
  const { removeDate, setAppointment, updateStateAppointment, setTherapist } =
    useActions();

  useEffect(() => setTime(""), [activeDate]);

  let times = useMemo(() => {
    let result: string[] = [];
    availableDates.forEach((d: Date) => {
      if (d.toDateString() === activeDate.toDateString()) {
        result.push(`${d.getHours()}:${d.getMinutes()}`);
      }
    });
    return result.length ? result : null;
  }, [activeDate]);

  const sendUpdateAppointment = (body: TUpdateAppointmentRequest) => {
    setChangeOption(appointmentOptions.Update);
    updateAppointment(body);
    updateStateAppointment(body);
  };
  const sendCreateAppointment = () => {
    setChangeOption(appointmentOptions.Create);
    makeAppointment({ date: activeDate, therapistId: therapist.id });
    setAppointment({ date: activeDate, therapist });
    setTherapist({ therapist });
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const [hours, minutes] = time.split(":");
    activeDate.setHours(+hours);
    activeDate.setMinutes(+minutes);
    removeDate(activeDate);
    typeof appointmentId === "number"
      ? sendUpdateAppointment({ appointmentId, date: activeDate })
      : sendCreateAppointment();
    if (resetAppointmentId) {
      resetAppointmentId();
    }
    setOpenModal(true);
    setTime("");
    setActiveDate(new Date());
  };

  return (
    <section className={makeAppointmentStyles.section}>
      {openModal && (
        <Modal
          onClose={() => {
            setChangeOption(appointmentOptions.Create);
            setOpenModal(false);
          }}
        >
          <AppointmentSuccessPopup
            type={changeOption}
            onClose={() => {
              setChangeOption(appointmentOptions.Create);
              setOpenModal(false);
            }}
          />
        </Modal>
      )}
      <p className={makeAppointmentStyles.text}>תאריכים פנויים עבור:</p>
      <p className={makeAppointmentStyles.text}>{therapist.name}</p>
      {(isError || isUpdateAppointmentError) && (
        <p className={`error`}>משהו השתבש, אנא נסה שוב מאוחר יותר</p>
      )}
      <div className={makeAppointmentStyles.calendarSection}>
        <BackArrowButton
          position={"center"}
          text={"חזרה לחיפוש"}
          onClick={onClose}
        />
        <Calendar
          calendarType={"hebrew"}
          locale={"he"}
          onClickDay={setActiveDate}
          value={activeDate}
          minDate={new Date()}
          prev2Label={null}
          next2Label={null}
          tileClassName={({ date }) => {
            if (
              availableDates.find(
                (d: Date) =>
                  date.toDateString() === d.toDateString() &&
                  date.toDateString() !== activeDate.toDateString()
              )
            ) {
              return makeAppointmentStyles.highlight;
            } else {
              return makeAppointmentStyles.tile;
            }
          }}
          className={makeAppointmentStyles.calendar}
        />
        <div className={makeAppointmentStyles.timesContainer}>
          <div className={makeAppointmentStyles.dateContainer}>
            <p className={makeAppointmentStyles.date}>
              {`${activeDate.getDate()}/${
                activeDate.getMonth() + 1
              }/${activeDate.getFullYear()}
            `}
            </p>
            <p className={makeAppointmentStyles.date}>
              {week[activeDate.getDay()]}
            </p>
          </div>
          {times ? (
            <>
              <ul className={makeAppointmentStyles.times}>
                {times.map((t, index) => (
                  <button
                    key={index}
                    className={`${makeAppointmentStyles.time} ${
                      t === time && makeAppointmentStyles.time_active
                    }`}
                    onClick={() => setTime(t)}
                  >
                    {t}
                  </button>
                ))}
              </ul>
              {time && (
                <button
                  className={makeAppointmentStyles.submitBtn}
                  type={"submit"}
                  onClick={onSubmit}
                >
                  {typeof appointmentId === "number"
                    ? "לשנות פגישה"
                    : "קבע פגישה"}
                </button>
              )}
            </>
          ) : (
            <p className={makeAppointmentStyles.text}>
              ביום זה אין שעות פנויות, אנא בחר יום אחר
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

export default MakeAppointment;
