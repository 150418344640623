import { IAllflowUser } from "../types/auth.types";
import { api } from "./api";
import { IGenderOption } from "../../types/types";

export const utilsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllflowUser: build.query<IAllflowUser[], { skip: boolean }>({
      query: () => ({
        url: `user/getAllflowUser`,
        method: "GET",
      }),
    }),
    getAllGenders: build.query<IGenderOption[], { skip: boolean }>({
      query: () => ({
        url: `user/getAllGenders`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllGendersQuery,
  useLazyGetAllGendersQuery,
  useGetAllflowUserQuery,
} = utilsApi;
