import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import chatStyles from "./chat.module.css";
import { mobileWidth } from "../../utils/constants";
import Message from "../message/message";
import useMediaQuery from "../../hooks/useMediaQuery";
import ChatSideBar from "../chatSidebar/chatSideBar";
import { IMessage, ITherapist } from "../../types/types";
import { getDate } from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { chatTherapists } from "../../utils/therapists";
import { testData } from "../../utils/user";

const Chat = ({
  therapist,
  setTherapist,
  openMenu,
}: {
  therapist: Partial<ITherapist>;
  setTherapist: (therapist: Partial<ITherapist>) => void;
  openMenu: boolean;
}) => {
  const mobile = useMediaQuery(mobileWidth);
  const user = useAppSelector(userSelector);
  const testUser = {
    ...testData,
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
  };

  const [value, setValue] = useState("");
  const [openSideBar, setOpenSideBar] = useState(true);
  const isConnected = useState(true);

  let messages: { date: Date; messages: IMessage[] }[] = [];
  therapist.messages?.forEach((m) => {
    const date = m.date;
    const group = messages.filter(
      (m) => m.date.toDateString() === date.toDateString()
    );
    if (group.length) {
      group[0].messages.push(m);
    } else {
      messages.push({ date: date, messages: [m] });
    }
  });

  const online = therapist.online ? "מחובר" : "לא מחובר";

  const submit = useCallback(() => {
    // if (isConnected && testUser && !!value.trim()) {
    //     dispatch(wsSendMessage({ message: value }));
    //     setValue('');
    // }
    setValue("");
  }, [value]);
  // }, [value, testUser, dispatch, isConnected]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [submit]);

  const onChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [therapist]);

  const inputPlaceholder = isConnected ? "כתוב הודעה" : "בעיות התחברות";
  const content = (
    <div className={chatStyles.container}>
      <div>
        <div className={chatStyles.nameContainer}>
          <img
            src={therapist.avatar}
            alt={therapist.name}
            className={chatStyles.avatar}
          />
          <div className={chatStyles.info}>
            <h1 className={chatStyles.name}>{therapist.name}</h1>
            <p className={chatStyles.online}>{online}</p>
          </div>
          {mobile && (
            <button
              className={chatStyles.closeBtn}
              onClick={() => setOpenSideBar(true)}
            />
          )}
        </div>
      </div>
      {messages.length ? (
        <div className={chatStyles.messagesWrapper}>
          {messages.map((m, index) => (
            <div key={index} className={chatStyles.dateSection}>
              <p className={chatStyles.date}>
                {getDate(m.date.toDateString())}
              </p>
              {m.messages?.map((m, ind) => (
                <Message
                  therapist={therapist}
                  message={m}
                  key={ind}
                  isOwnMessage={testUser.id === m.id}
                />
              ))}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className={chatStyles.text}>אין הודעות עדיין</div>
      )}
      <div className={chatStyles.replyBar}>
        {!value && <span className={chatStyles.placeholder}>כתוב הודעה</span>}
        <textarea
          className={chatStyles.input}
          placeholder={inputPlaceholder}
          onChange={onChange}
          value={value}
          disabled={!isConnected}
        />
        <p className={chatStyles.message}>{value}</p>
        <button className={chatStyles.sendBtn} onClick={submit} />
      </div>
    </div>
  );
  return (
    <>
      {mobile && openSideBar ? (
        <>
          <ChatSideBar
            allTherapists={chatTherapists}
            setTherapist={setTherapist}
            setOpenSideBar={setOpenSideBar}
            therapist={therapist}
            openMenu={openMenu}
          />
        </>
      ) : (
        content
      )}
    </>
  );
};

export default Chat;
