import prevencherChatStyles from "./prevencherChat.module.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Message from "../message/message";
import { israeliMobileRegExp, venchyData } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import {
  currentQuestionIdSelector,
  currentQuestionSelector,
  prevencherPOCMessagesSelector,
  questionsOptionsSelector,
} from "../../services/selectors/user.selectors";
import { useActions } from "../../services/hooks/useActions";
import {
  useInsertAnswerQuestionMutation,
  useLazyGetPOCQuestionByIdQuery,
} from "../../services/api/user.api";
import { IQuestionOption } from "../../services/types/user.types";
import Loader from "../loader/loader";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";

const startMessage =
  "היי, נעים מאוד :) אני פריוונצ'ר. ובקיצור, וונצ'י. שמח שאנו נפגשים כאן. אני אעשה את המיטב על מנת לעזור לך.";
const lastMessage =
  "תודה על השיתוף, ברגעים אלו אנו ממשיכים לאתר עבורך מענה מתאים. יש לשמור על זמינות. יתכן ויתקשרו אליך משיחה חסויה, אני מעדכן אותך שזה אנחנו :) במהלך השעות הקרובות לאחר סיום סיום השיחה, ישלח SMS שבו אני אעדכן אותך שההמלצות שלך מוכנות ומחכות לך באזור האישי. עד אז שיהיה בהצלחה בינתיים :)";

function PrevencherChatPOC() {
  const user = { id: "guest" };
  const questionnaireId = 26;
  const lastElRef = useRef<HTMLDivElement | null>(null);
  const messages = useAppSelector(prevencherPOCMessagesSelector);
  const options = useAppSelector(questionsOptionsSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const { setPOCMessages: setMessages, setOptions } = useActions();
  const [getQuestionById] = useLazyGetPOCQuestionByIdQuery();
  const [insertAnswer] = useInsertAnswerQuestionMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (!messages.length) {
      setMessages({
        messageId: "start",
        id: prevencherId,
        date: new Date(),
        unread: false,
        message: startMessage,
        nextQuestionId: 11,
      });
      setIsLoading(true);
      delay(1500).then(() => {
        getQuestionById({
          nextQuestionId: 11,
          skip: false,
        }).then(() => setIsLoading(false));
      });
    }
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      setShowInput(true);
    }
  }, [currentQuestion]);

  const handleTelInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };

  const onOptionClick = (option: IQuestionOption) => {
    setOptions([]);
    setIsLoading(true);
    setMessages({
      messageId: option.Id,
      id: user.id,
      date: new Date(),
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    delay(1500).then(() => {
      setIsLoading(false);
      insertAnswer({
        Id: option.Id.toString(),
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: "0",
        Answer: option.Desc,
      }).then((response) => {
        if ("data" in response) {
          if (currentQuestion?.IsEnd) {
            setMessages({
              messageId: "last",
              id: prevencherId,
              date: new Date(),
              unread: false,
              message: lastMessage,
              nextQuestionId: -1,
            });
            return;
          }
          getQuestionById({
            nextQuestionId: option.NextQuestionId,
            skip: !option.NextQuestionId,
          });
        }
      });
    });
  };
  const onInputSubmit = () => {
    if (!inputValue) {
      return;
    }
    if (currentQuestion?.Id === 24 && !israeliMobileRegExp.test(inputValue)) {
      console.log(israeliMobileRegExp.test(inputValue));
      setMessages({
        messageId: `${inputValue}error`,
        id: prevencherId,
        date: new Date(),
        unread: false,
        message: `${inputValue} - מספר נייד לא תקין`,
        nextQuestionId: currentQuestion?.NextQuestionId,
      });
      return;
    }
    setShowInput(false);
    setIsLoading(true);
    setMessages({
      messageId: inputValue,
      id: user.id,
      date: new Date(),
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      insertAnswer({
        Id: currentQuestion?.Id.toString() || "",
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: "0",
        Answer: inputValue,
      }).then((response) => {
        if ("data" in response) {
          setIsLoading(false);
          setInputValue("");
          if (currentQuestion?.IsEnd) {
            setMessages({
              messageId: "last",
              id: prevencherId,
              date: new Date(),
              unread: false,
              message: lastMessage,
              nextQuestionId: -1,
            });
            return;
          }
          currentQuestion?.NextQuestionId &&
            getQuestionById({
              nextQuestionId: currentQuestion.NextQuestionId,
              skip: false,
            });
        }
      });
    });
  };
  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onInputSubmit();
      }
    },
    [onInputSubmit]
  );

  return (
    <div
      className={`${prevencherChatStyles.section} ${prevencherChatStyles.section_POC}`}
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <Message
            key={message.messageId}
            isOwnMessage={user.id === message.id}
            therapist={venchyData}
            message={message}
          />
        ))}
      {isLoading && (
        <div>
          <Loader />
          <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
        </div>
      )}
      {options.length > 0 && (
        <ul
          className={`${prevencherChatStyles.variants} ${prevencherChatStyles.variants_poc}`}
        >
          {options.map((o) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={o.Id}
              onClick={() => {
                onOptionClick(o);
              }}
            >
              <span>{o.Desc}</span>
            </li>
          ))}
        </ul>
      )}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 19 ? 300 : 10}
            onChange={(e) =>
              currentQuestion?.Id === 24
                ? handleTelInputChange(e)
                : setInputValue(e.target.value)
            }
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      <div ref={lastElRef} />
    </div>
  );
}

export default PrevencherChatPOC;
