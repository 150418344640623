import matchingStyles from "./matching.module.css";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { replaceVariables } from "../../utils/utils";

function Matching() {
  const user = useAppSelector(userSelector);

  return (
    <div className={matchingStyles.section}>
      <h3 className={matchingStyles.section__title}>
        {user.firstName} ,תודה על האמון
      </h3>
      <p className={matchingStyles.section__text}>
        זה בדיוק המומחיות שלי, בשביל זה אני כאן.
      </p>
      <p className={matchingStyles.section__text}>
        אשאל אותך מספר שאלות ואמצא עבורך את המומחה הטוב ביותר עבורך.
      </p>
      <p className={matchingStyles.section__text}>
        {replaceVariables("@Gender_ready?", user)}
      </p>
      <Link
        to={"/prevencher"}
        state={{ pathname: "chat" }}
        className={`${matchingStyles.section__btn} button hover`}
      >
        {replaceVariables("כן, אני @Gender_ready  ", user)}
      </Link>
    </div>
  );
}

export default Matching;
