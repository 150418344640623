import noAppointmentsStyles from "./noAppointments.module.css";
import myCalendarImg from "../../images/myCalendarImg.png";
import { useNavigate } from "react-router-dom";

function NoAppointments() {
  const navigate = useNavigate();
  return (
    <div className={noAppointmentsStyles.section}>
      <img
        className={noAppointmentsStyles.section__img}
        src={myCalendarImg}
        alt={"גבר עומד עם עט ולוח שנה"}
      />
      <h2 className={noAppointmentsStyles.section__title}>
        טרם נקבעו לך פגישות
      </h2>
      <button
        type={"button"}
        className={`button ${noAppointmentsStyles.section__btn} hover`}
        onClick={() => {
          navigate("search");
        }}
      >
        קבע פגישה עם מומחה
      </button>
    </div>
  );
}

export default NoAppointments;
