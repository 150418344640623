import changePasswordPopupStyles from "./changePasswordSuccessPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";

interface ChangePasswordSuccessPopup {
  onClose: () => void;
  type?: string;
}

function ChangePasswordSuccessPopup({
  onClose,
  type = "password",
}: ChangePasswordSuccessPopup) {
  return (
    <article className={changePasswordPopupStyles.popup}>
      <div className={changePasswordPopupStyles.popup__content}>
        <button
          type="button"
          onClick={onClose}
          className={changePasswordPopupStyles.popup__closeBtn}
        />
        <h2 className={changePasswordPopupStyles.popup__title}>
          {type === "password" ? "הוגדרה סיסמה חדשה" : "הוגדרה תמונה חדשה!"}
        </h2>
        <p className={changePasswordPopupStyles.popup__text}>
          תודה על העדכון. אנחנו נעדכן את הפרטים החדשים שהזנת.
        </p>
        <img
          src={venchyImg}
          alt={"תמונה של וונצ’י"}
          className={changePasswordPopupStyles.popup__img}
        />
        <button
          className={`button hover ${changePasswordPopupStyles.popup__btn}`}
          onClick={onClose}
        >
          אישור
        </button>
      </div>
    </article>
  );
}

export default ChangePasswordSuccessPopup;
