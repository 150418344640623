import updateCardFormStyles from "./updateCardForm.module.css";
import React, { useState } from "react";
import {
  cardInitialState,
  cvvLength,
  minCardNumberLength,
} from "../../../utils/constants";
import { useAppSelector } from "../../../services/hooks";
import UpdateUserInfoSuccessPopup from "../../modals/updateUserInfoSuccessPopup/updateUserInfoSuccessPopup";
import Modal from "../../modals/modal/Modal";
import { userSelector } from "../../../services/selectors/user.selectors";

function UpdateCardForm() {
  const [state, setState] = useState(cardInitialState);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const user = useAppSelector(userSelector);

  const disabled =
    !state.cardNumber ||
    state.cardNumber.length < minCardNumberLength ||
    !state.cardUser ||
    !state.cvv ||
    !state.exp;

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setOpenModal(true);
    setState(cardInitialState);
  };

  return (
    <section className={updateCardFormStyles.section}>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <UpdateUserInfoSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}
      <h2 className={updateCardFormStyles.section__title}>עדכון פרטי אשראי</h2>
      <div className={updateCardFormStyles.card}>
        <p className={updateCardFormStyles.cardNumber}>
          xxxx xxxx xxxx {user.card ? user.card : "xxxx"}
        </p>
        <div className={updateCardFormStyles.cardInfo}>
          <p className={updateCardFormStyles.cardName}>{user.fullName}</p>
          <p className={updateCardFormStyles.cardName}>xx/xx</p>
        </div>
      </div>
      <form
        onSubmit={submitForm}
        className={updateCardFormStyles.form}
        id="updateCard"
      >
        <div className={updateCardFormStyles.number}>
          <label htmlFor="cardNumber" className={updateCardFormStyles.label}>
            מספר כרטיס
          </label>
          <input
            type="number"
            name="cardNumber"
            id="cardNumber"
            autoFocus={true}
            value={state.cardNumber ? parseInt(state.cardNumber) : ""}
            onChange={handleChange}
            required={true}
            minLength={minCardNumberLength}
            className={updateCardFormStyles.input}
          />
        </div>
        <div className={updateCardFormStyles.name}>
          <label htmlFor={"cardUser"} className={updateCardFormStyles.label}>
            שם בעל הכרטיס
          </label>
          <input
            type="text"
            name="cardUser"
            id="cardUser"
            value={state.cardUser}
            onChange={handleChange}
            required={true}
            className={updateCardFormStyles.input}
          />
        </div>
        <div className={updateCardFormStyles.exp}>
          <label
            htmlFor="exp"
            className={`${updateCardFormStyles.label} ${updateCardFormStyles.label_small}`}
          >
            תוקף
          </label>
          <input
            type="date"
            name="exp"
            id="exp"
            value={state.exp.toString()}
            min="2018-03"
            onChange={handleChange}
            required={true}
            className={`${updateCardFormStyles.input} ${updateCardFormStyles.input_small} ${updateCardFormStyles.input_date}`}
          />
        </div>
        <div className={updateCardFormStyles.cvv}>
          <label
            htmlFor="cvv"
            className={`${updateCardFormStyles.label} ${updateCardFormStyles.label_small}`}
          >
            CVV
          </label>
          <input
            type="number"
            name="cvv"
            id="cvv"
            value={state.cvv || ""}
            onChange={handleChange}
            required={true}
            minLength={cvvLength}
            maxLength={cvvLength}
            className={`${updateCardFormStyles.input} ${updateCardFormStyles.input_small}`}
          />
        </div>
      </form>
      <button
        type="submit"
        form="updateCard"
        disabled={disabled}
        className={`button  ${updateCardFormStyles.submitBtn} ${
          disabled ? updateCardFormStyles.submitBtn_disabled : "hover"
        }`}
      >
        שמור
      </button>
    </section>
  );
}
export default UpdateCardForm;
