import faqStyles from "./faq.module.css";
import { useState } from "react";
import { questions } from "../../utils/constants";
import FaqItem from "../faqItem/faqItem";

function Faq() {
  const [toggle, setToggle] = useState({});
  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };
  return (
    <section className={faqStyles.faq}>
      <h2 className={faqStyles.faq__title}>שאלות ותשובות</h2>
      <ul className={faqStyles.faq__list}>
        {questions.map((question, index) => (
          <FaqItem
            key={index}
            index={index}
            theme={question}
            toggle={toggle}
            toggleAnswer={toggleAnswer}
            setToggle={setToggle}
          />
        ))}
      </ul>
    </section>
  );
}

export default Faq;
