import urgentStyles from "./urgent.module.css";
import { setCookie } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { guestUserFlowIdSelector } from "../../services/selectors/user.selectors";
import { useLazyGetCurrentFlowQuery } from "../../services/api/user.api";
import { useActions } from "../../services/hooks/useActions";

function Urgent() {
  const navigate = useNavigate();
  const guestUserFlowId = useAppSelector(guestUserFlowIdSelector);
  const [getGuestCurrentFlowQuery] = useLazyGetCurrentFlowQuery();
  const { setUserFlowId } = useActions();
  const onClick = () => {
    setCookie("accessToken", "guestToken");
    setUserFlowId(guestUserFlowId);
    getGuestCurrentFlowQuery({ usersFlowId: guestUserFlowId, userId: "0" });
    navigate("/guests_chat");
    // navigate("/home");
  };
  return (
    <div>
      <p className={urgentStyles.urgent}>דחוף? דלג ו</p>
      <button
        type={"button"}
        className={urgentStyles.urgent__link}
        onClick={onClick}
      >
        הכנס כאורח
      </button>
    </div>
  );
}

export default Urgent;
