import popupStyles from "./appointment_success_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { appointmentOptions } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../closeButton/closeButton";

interface ISupportSuccessPopup extends IPopupWithoutProps {
  type: appointmentOptions;
}
function AppointmentSuccessPopup({
  onClose,
  type = appointmentOptions.Create,
}: ISupportSuccessPopup) {
  const title =
    type === appointmentOptions.Create
      ? "הפגישה נוצרה בהצלחה!"
      : type === appointmentOptions.Update
      ? "הפגישה שונתה בהצלחה!"
      : "הפגישה בוטלה בהצלחה!";

  const navigate = useNavigate();

  return (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>{title}</h2>
      <p className={popupStyles.popup__text}>
        ניתן להיכנס לאזור יומן פגישות ולצפות בפגישות שנקבעו. בלחיצה על הפגישה
        ניתן לצפות בפרטים המלאים.
      </p>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button ${popupStyles.popup__btn} ${popupStyles.popup__btn_type_calendar} hover`}
        onClick={() => {
          navigate("/treatment", {
            replace: true,
            state: { pathname: "myCalendar" },
          });
          onClose();
        }}
      >
        לאזור יומן פגישות
      </button>
      <button
        className={`button ${popupStyles.popup__btn} hover`}
        onClick={() => {
          navigate("/treatment/search");
          onClose();
        }}
      >
        לתיאום פגישה נוספת
      </button>
    </article>
  );
}

export default AppointmentSuccessPopup;
