import prevencherChatStyles from "./prevencherChat.module.css";
import { venchyData } from "../../utils/constants";
import React, { useEffect, useRef, useState } from "react";
import Message from "../message/message";
import { useAppSelector } from "../../services/hooks";
import {
  currentQuestionSelector,
  currentQuestionIdSelector,
  endQuestionSelector,
  isEndSelector,
  nextQuestionIdSelector,
  prevencherChatMessagesSelector,
  questionnaireIdSelector,
  questionsOptionsSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import {
  useGetCurrentFlowQuery,
  useInsertAnswerQuestionMutation,
  useLazyGetNextQuestionByQuestionnaireIdQuery,
  useLazyGetQuestionByQuestionnaireIdQuery,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import { IQuestionOption } from "../../services/types/user.types";
import { useActions } from "../../services/hooks/useActions";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";
import { replaceVariables } from "../../utils/utils";

function PrevencherChat() {
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const user = useAppSelector(userSelector);
  const nextQuestionId = useAppSelector(nextQuestionIdSelector);
  const questionnaireId = useAppSelector(questionnaireIdSelector);
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const questionsOptions = useAppSelector(questionsOptionsSelector);
  const isEnd = useAppSelector(isEndSelector);
  const endQuestion = useAppSelector(endQuestionSelector);
  useGetCurrentFlowQuery({ usersFlowId: 3, userId: user.id });

  const [isDataLoaded, setIsDataLoaded] = useState(questionnaireId >= 0);
  const messages = useAppSelector(prevencherChatMessagesSelector);
  const { setMessages } = useActions();
  const [options, setOptions] = useState<IQuestionOption[]>([]);

  const [getQuestionById, getQuestionByIdResult] =
    useLazyGetQuestionByQuestionnaireIdQuery();
  const [getNextQuestionById] = useLazyGetNextQuestionByQuestionnaireIdQuery();
  const [insertAnswer, insertAnswerResult] = useInsertAnswerQuestionMutation();

  const { setNextQuestion } = useActions();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    //@ts-ignore
    if (nextQuestionId === null || nextQuestionId === "null") {
      setNextQuestion();
    }
  }, [nextQuestionId]);
  useEffect(() => {
    if (nextQuestionId >= 0 && questionnaireId >= 0) {
      setIsDataLoaded(true);
    }
  }, [nextQuestionId, questionnaireId]);
  useEffect(() => {
    getQuestionById({
      currentQuestionId: questionnaireId,
      nextQuestionId,
      skip: !isDataLoaded,
    });
  }, [isDataLoaded]);

  useEffect(() => {
    if (getQuestionByIdResult.status === "fulfilled" && currentQuestion?.Desc) {
      setMessages({
        id: prevencherId,
        date: new Date(),
        message:
          replaceVariables(currentQuestion?.Desc, user) ||
          replaceVariables(endQuestion, user),
        unread: false,
        nextQuestionId: 0,
      });
    }
  }, [getQuestionByIdResult.status]);

  useEffect(() => {
    setOptions(questionsOptions);
  }, [questionsOptions]);

  const scrollToBottom = () => {
    setTimeout(
      () => lastElRef.current?.scrollIntoView({ behavior: "smooth" }),
      0
    );
  };

  const onOptionClick = (option: IQuestionOption) => {
    setMessages({
      id: user.id,
      date: new Date(),
      unread: false,
      message: replaceVariables(option.Desc, user),
      nextQuestionId: option.NextQuestionId,
    });
    setOptions([]);
    insertAnswer({
      Id: option.Id.toString(),
      QuestionnaireId: questionnaireId.toString(),
      QuestionsId: currentQuestionId.toString(),
      UsersId: user.id.toString(),
      Answer: option.Desc,
    }).then((response) => {
      if ("data" in response) {
        option.NextQuestionId
          ? getNextQuestionById({
              currentQuestionId: questionnaireId,
              nextQuestionId: option.NextQuestionId,
              skip: isEnd,
            })
          : setMessages({
              id: prevencherId,
              date: new Date(),
              message: replaceVariables(endQuestion, user) || "",
              unread: false,
              nextQuestionId: 0,
            });
      }
    });
  };

  return (
    <div className={`${prevencherChatStyles.section}`}>
      {messages.length > 0 &&
        messages.map((message, index) => (
          <Message
            key={index}
            isOwnMessage={message.id != prevencherId}
            therapist={venchyData}
            message={message}
          />
        ))}
      {(getQuestionByIdResult.status !== "fulfilled" ||
        insertAnswerResult.status === "pending") && <Loader />}
      {messages.length > 0 && options.length > 0 && (
        <ul className={prevencherChatStyles.variants}>
          {options.map((o, index) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={index}
              onClick={() => onOptionClick(o)}
            >
              <span>{replaceVariables(o.Desc, user)}</span>
            </li>
          ))}
        </ul>
      )}
      <div ref={lastElRef} />
    </div>
  );
}

export default PrevencherChat;
