import therapistCardStyles from "./therapistCard.module.css";
import { ITherapist } from "../../types/types";
import { useAppSelector } from "../../services/hooks";
import { userTherapistsSelector } from "../../services/selectors/user.selectors";

function TherapistCard({
  therapist,
  setTherapist,
}: {
  therapist: ITherapist;
  setTherapist: (t: ITherapist) => void;
}) {
  const myTherapists = useAppSelector(userTherapistsSelector);
  const isMyTherapist = myTherapists.find((t) => t.id === therapist.id);
  return (
    <li
      className={therapistCardStyles.myTreatment__li}
      onClick={() => setTherapist(therapist)}
    >
      <div
        className={`${therapistCardStyles.myTreatment__online} ${
          therapist.online ? therapistCardStyles.myTreatment__online_active : ""
        }`}
      >
        <img
          className={therapistCardStyles.myTreatment__avatar}
          src={therapist.avatar}
          alt={therapist.name}
        />
      </div>
      <div className={therapistCardStyles.myTreatment__therapist}>
        <p className={therapistCardStyles.myTreatment__name}>
          {therapist.name}
        </p>
        <div className={therapistCardStyles.myTreatment__therapistInfo}>
          <p className={therapistCardStyles.myTreatment__status}>
            {therapist.status}
          </p>
          {isMyTherapist && (
            <p className={therapistCardStyles.myTreatment__tag}>המטפל שלי</p>
          )}
        </div>
      </div>
    </li>
  );
}
export default TherapistCard;
