import React, { useState } from "react";
import { IPrescription } from "../../types/types";
import Modal from "../modals/modal/Modal";
import RenewPrescriptionPopup from "../modals/renewPrescription_success_popup/renewPrescription_success_popup";
import myPrescriptionsStyles from "./myPrescriptions.module.css";
import { getPrintDate, isExpired } from "../../utils/utils";
import noDocksImg from "../../images/noPrescriptions.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { testData } from "../../utils/user";
import {
  useFetchPrescriptionsQuery,
  useUpdatePrescriptionMutation,
} from "../../services/api/user.api";

function MyPrescriptions() {
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>(
    // testData.prescriptions
    []
  );
  //testData.prescriptions - to check prescriptions layout | []
  const [prescriptionIdToUpdate, setPrescriptionIdToUpdate] = useState("");
  const [openModalRenew, setOpenModalRenew] = useState(false);
  const [toggle, setToggle] = useState({});

  const { data, isError } = useFetchPrescriptionsQuery();
  const [updatePrescription, { isError: isUpdatePrescriptionError }] =
    useUpdatePrescriptionMutation();

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const renewPrescription = (e: React.MouseEvent, p: IPrescription) => {
    e.stopPropagation();
    setPrescriptionIdToUpdate(p.id);
    updatePrescription(p.id);
    setOpenModalRenew(true);
  };
  const createPDF = async (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    const data = document.getElementById(index.toString());
    html2canvas(data!).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const contentDataURL = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
      const position = 10;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("prescription.pdf"); // Generated PDF
    });
  };

  return (
    <section className={myPrescriptionsStyles.section}>
      {openModalRenew && (
        <Modal onClose={() => setOpenModalRenew(false)}>
          <RenewPrescriptionPopup onClose={() => setOpenModalRenew(false)} />
        </Modal>
      )}
      <h2 className={myPrescriptionsStyles.section__title}>מרשמים</h2>
      {prescriptions?.length ? (
        <ul className={myPrescriptionsStyles.prescriptionList}>
          {prescriptions.map((p, index) => (
            <li
              key={index}
              className={`${myPrescriptionsStyles.prescriptionLi} ${
                toggle[index as keyof typeof setToggle]
                  ? myPrescriptionsStyles.prescriptionLi_long
                  : ""
              }`}
              onClick={() => toggleAnswer(index)}
            >
              <p className={myPrescriptionsStyles.title}>המרשמים שלי</p>
              <div
                className={myPrescriptionsStyles.prescription}
                id={`${index}`}
              >
                <div className={myPrescriptionsStyles.prescription__heading}>
                  <p className={myPrescriptionsStyles.prescription__date}>
                    {getPrintDate(p.date)}
                  </p>
                  <h3 className={myPrescriptionsStyles.prescription__title}>
                    {`${p.medicine} ${p.doze}`}
                  </h3>
                  <p className={myPrescriptionsStyles.prescription__expired}>
                    {isExpired(p.exp) ? "מרשם פג תוקף" : "מרשם בתוקף"}
                  </p>
                </div>
                <div className={myPrescriptionsStyles.prescription__content}>
                  <article className={myPrescriptionsStyles.therapist}>
                    <div
                      className={`${myPrescriptionsStyles.therapist__online} ${
                        p.therapist.online &&
                        myPrescriptionsStyles.therapist__online_active
                      }`}
                    >
                      <img
                        src={p.therapist.avatar}
                        alt={p.therapist.name}
                        className={myPrescriptionsStyles.therapist__avatar}
                      />
                    </div>
                    <div className={myPrescriptionsStyles.therapist__info}>
                      <p className={myPrescriptionsStyles.therapist__name}>
                        {p.therapist.name}
                      </p>
                      <div
                        className={myPrescriptionsStyles.therapist__statusInfo}
                      >
                        <p className={myPrescriptionsStyles.therapist__status}>
                          {p.therapist.status}
                        </p>
                        <p className={myPrescriptionsStyles.therapist__tag}>
                          {p.therapist.tag}
                        </p>
                      </div>
                    </div>
                  </article>
                  <p
                    className={myPrescriptionsStyles.prescription__expiredText}
                  >
                    תוקף המרשם
                  </p>
                  <p
                    className={
                      myPrescriptionsStyles.prescription__expirationInfo
                    }
                  >{`${getPrintDate(p.date)} - ${getPrintDate(p.exp)}`}</p>
                  <p
                    className={`${
                      myPrescriptionsStyles.prescription__expiredTag
                    } ${
                      isExpired(p.exp) &&
                      myPrescriptionsStyles.prescription__expiredTag_expired
                    }`}
                  >
                    {isExpired(p.exp) ? " לא בתוקף" : "בתוקף"}
                  </p>
                  {isExpired(p.exp) ? (
                    <button
                      type={"button"}
                      className={`${myPrescriptionsStyles.btn} ${myPrescriptionsStyles.btn_type_renew} hover`}
                      onClick={(e) => renewPrescription(e, p)}
                    >
                      שלח בקשה לחידוש המרשם
                    </button>
                  ) : (
                    <div
                      className={myPrescriptionsStyles.instructionsContainer}
                    >
                      <p className={myPrescriptionsStyles.instructions}>
                        הוראות שימוש
                      </p>
                      <p className={myPrescriptionsStyles.instructions__text}>
                        {p.instructions}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {isUpdatePrescriptionError && p.id === prescriptionIdToUpdate && (
                <p className={"error"}>משהו השתבש, אנא נסה שוב מאוחר יותר</p>
              )}
              {!isExpired(p.exp) && (
                <button
                  className={`${myPrescriptionsStyles.btn} ${myPrescriptionsStyles.btn_type_print} hover`}
                  onClick={(e) => createPDF(e, index)}
                >
                  הדפס מרשם
                </button>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          <img
            src={noDocksImg}
            alt={"מרשמים"}
            className={myPrescriptionsStyles.img}
          />
          <p className={myPrescriptionsStyles.noPrescriptions}>
            לא נמצאו מרשמים
          </p>
          <button
            type="button"
            onClick={() => {
              setPrescriptions(testData.prescriptions);
            }}
            className={`button hover ${myPrescriptionsStyles.nextBtn}`}
          >
            בקש מרשם חדש
          </button>
        </>
      )}
    </section>
  );
}

export default MyPrescriptions;
