import { TPrevencherForGuestsQuestion } from "../types/types";

export const urgentQuestionsForUser: TPrevencherForGuestsQuestion[] = [
  {
    messageId: "urgent1",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: "היי @name. איך תרצה לשוחח היום?",
    unread: false,
    nextQuestionId: 1,
    dataType: "options",
    options: [
      { id: 0, desc: "צ'אט", value: "way" },
      { id: 1, desc: "וידאו", value: "way" },
      { id: 2, desc: "טלפון", value: "way" },
    ],
  },
  {
    messageId: "urgent1",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: `על מה תרצה לדבר`,
    unread: false,
    nextQuestionId: 2,
    dataType: "options",
    options: [
      { id: 0, desc: "בדידות", value: "theme" },
      { id: 1, desc: "ייעוץ פסיכולוגי", value: "theme" },
      { id: 2, desc: "ייעוץ פסיכיאטרי", value: "theme" },
      { id: 3, desc: "ישיחת נפש", value: "theme" },
      { id: 4, desc: "חרדות", value: "theme" },
      { id: 5, desc: "דיכאון", value: "theme" },
      { id: 6, desc: "מצוקה שקשה לי איתה", value: "theme" },
      { id: 7, desc: "אירוע שקרה לי היום", value: "theme" },
      { id: 8, desc: "אחר", value: "theme", input: true },
    ],
  },
  {
    messageId: "urgent2",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: ` אשמח שתגדר את רמת הדחיפות שלך (3 הכי גבוה)`,
    unread: false,
    nextQuestionId: 3,
    dataType: "options",
    options: [
      { id: 0, desc: "1", value: "urgent" },
      { id: 1, desc: "2", value: "urgent" },
      { id: 2, desc: "3", value: "urgent" },
    ],
  },
  {
    messageId: "urgent3",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: `האם יש משהו נוסף שאתה רוצה שאדע`,
    unread: false,
    nextQuestionId: 4,
    dataType: "options",
    options: [
      { id: 0, desc: "לא", value: "comment" },
      { id: 1, desc: "כן, ...", value: "comment", textArea: true },
    ],
  },
  {
    messageId: "urgent4",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: `.תודה על הפנייה אנחנו מאתרים עבורך מענה מתאים. בזמן ההמתנה שלך אני רוצה לגלות שכבר שמתי לך כמה סרטונים בתרגילים שלי  שעוסקים בדיוק בנושאים שכתבת עליהם. למעבר לסרטונים לחץ כאן: @link`,
    unread: false,
    nextQuestionId: -1,
    dataType: "options",
    options: [],
  },
];
