import prevencherChatStyles from "./prevencherChat.module.css";
import React, { useEffect, useRef, useState } from "react";
import {
  TMessageWithNextQuestionId,
  TPrevencherChatForGuestsOption,
  TPrevencherForGuestsQuestion,
  TUrgentForm,
  TUrgentFormKeys,
} from "../../types/types";
import Message from "../message/message";
import { venchyData } from "../../utils/constants";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";
import { urgentQuestionsForUser } from "../../utils/prevencherUrgentQuestions";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import Loader from "../loader/loader";

function PrevencherChatUrgent() {
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const user = useAppSelector(userSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [questionnaire, setQuestionnaire] = useState<
    TPrevencherForGuestsQuestion[]
  >(urgentQuestionsForUser);
  const [messages, setMessages] = useState<TMessageWithNextQuestionId[]>([
    {
      messageId: questionnaire[index].message,
      id: "0",
      nextQuestionId: 1,
      message: questionnaire[index].message.replace(/@name/g, user.firstName),
      date: new Date(),
      unread: false,
      userId: prevencherId,
    },
  ]);
  const [options, setOptions] = useState<TPrevencherChatForGuestsOption[]>(
    questionnaire[index].options
  );

  const [form, setForm] = useState<TUrgentForm>({
    way: "",
    theme: "",
    urgent: "",
    comment: "",
  });
  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onOptionClick = (option: TPrevencherChatForGuestsOption) => {
    setIsLoading(true);
    const currentMessage = questionnaire[index];
    setMessages((prev) => [
      ...prev,
      {
        messageId: option.desc,
        id: "0",
        nextQuestionId: currentMessage.nextQuestionId,
        message: option.desc,
        userId: user.id,
        unread: false,
        date: new Date(),
      },
    ]);
    setOptions([]);
    delay(1500).then(() => {
      setIsLoading(false);
      setForm((prev) => ({ ...prev, [option.value as string]: option.desc }));
      console.log(form);
      const nextMessage = questionnaire[currentMessage.nextQuestionId];
      if (nextMessage?.dataType === "options") {
        setOptions(nextMessage.options);
      }
      setIndex(currentMessage.nextQuestionId);
      setMessages((prev) => [
        ...prev,
        {
          messageId: index.toString(),
          id: "0",
          nextQuestionId: nextMessage?.nextQuestionId,
          message: nextMessage?.message || "מצוין",
          userId: prevencherId,
          unread: false,
          date: new Date(),
        },
      ]);
    });
  };

  const onInputSubmit = (inputName: TUrgentFormKeys) => {
    if (form[inputName].length < 3) {
      setMessages((prev) => [
        ...prev,
        {
          messageId: index.toString(),
          id: "0",
          nextQuestionId: questionnaire[index].nextQuestionId,
          message: "אנא נסה לדייק יותר",
          userId: prevencherId,
          date: new Date(),
          unread: false,
        },
      ]);
    } else {
      setOptions([]);
      setIsLoading(true);
      setMessages((prev) => [
        ...prev,
        {
          messageId: index.toString(),
          id: "0",
          nextQuestionId: questionnaire[index].nextQuestionId,
          message: form[inputName],
          userId: user.id,
          date: new Date(),
          unread: false,
        },
      ]);
      delay(1500).then(() => {
        setIsLoading(false);
        const currentMessage = questionnaire[index];
        const nextMessage = questionnaire[currentMessage.nextQuestionId];
        if (nextMessage?.dataType === "options") {
          setOptions(nextMessage.options);
        }
        setIndex(currentMessage.nextQuestionId);

        setMessages((prev) => [
          ...prev,
          {
            messageId: index.toString(),
            id: "0",
            nextQuestionId: nextMessage?.nextQuestionId,
            message: nextMessage?.message || "מצוין",
            userId: prevencherId,
            unread: false,
            date: new Date(),
          },
        ]);
      });
    }
  };

  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  return (
    <div className={`${prevencherChatStyles.section}`}>
      {messages.map((message, index) => (
        <Message
          key={index}
          isOwnMessage={message.userId === user.id}
          therapist={venchyData}
          message={message}
        />
      ))}
      {isLoading && (
        <div>
          <Loader />
          <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
        </div>
      )}
      {options.length > 0 && (
        <ul className={prevencherChatStyles.variants}>
          {options.map((o, index) => {
            return o.input ? (
              <li className={prevencherChatStyles.variant_input} key={index}>
                <input
                  type={"text"}
                  required={true}
                  name={o.value}
                  value={form.theme}
                  placeholder={o.desc}
                  onChange={(e) => {
                    e.preventDefault();
                    setForm((prev) => ({ ...prev, theme: e.target.value }));
                  }}
                  className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
                />
                <button
                  type={"submit"}
                  className={prevencherChatStyles.sendBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    onInputSubmit(o.value as TUrgentFormKeys);
                  }}
                />
              </li>
            ) : o.textArea ? (
              <li className={prevencherChatStyles.variant_input} key={index}>
                <textarea
                  className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input} ${prevencherChatStyles.textArea}`}
                  placeholder={o.desc}
                  value={form.comment}
                  onChange={(e) => {
                    e.preventDefault();
                    setForm((prev) => ({ ...prev, comment: e.target.value }));
                  }}
                />
                <button
                  type={"submit"}
                  className={prevencherChatStyles.sendBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    onInputSubmit(o.value as TUrgentFormKeys);
                  }}
                />
              </li>
            ) : (
              <li
                className={`${prevencherChatStyles.variant}  hover`}
                key={index}
                onClick={() => onOptionClick(o)}
              >
                <span>{o.desc}</span>
              </li>
            );
          })}
        </ul>
      )}
      <div ref={lastElRef} />
    </div>
  );
}

export default PrevencherChatUrgent;
