import loginStyles from "../../../pages/login/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { authApi } from "../../../services/api/auth.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { useActions } from "../../../services/hooks/useActions";
import { useFormik } from "formik";
import { loginSMSValidationSchema } from "../../../utils/validation";

function LoginSmsForm() {
  const navigate = useNavigate();
  const { setPhone } = useActions();
  const loginError =
    useAppSelector(errorUserSelector) || "משהו השתבש, אנא נסה שוב מאוחר יותר";

  const [loginWithCode, { status, isError, isLoading }] =
    authApi.useLoginWithCodeMutation();

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: loginSMSValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission
      loginWithCode({ phone: values.phone }).then((response) => {
        console.log(response);
        if ("data" in response) {
          navigate("enter_code");
          setPhone(values.phone);
        }
      });
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={loginStyles.login__form}>
      <p className={loginStyles.login__formText}>מסרון ישלח למספר נייד שלך</p>
      <label htmlFor="name" className={loginStyles.login__label}>
        מספר נייד שלי
      </label>
      <input
        type="text"
        className={`${loginStyles.login__input}  ${
          formik.errors.phone && loginStyles.login__input_invalid
        }`}
        name="phone"
        required={true}
        placeholder="מספר נייד"
        value={formik.values.phone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.errors.phone && (
        <p className={`${loginStyles.login__invalid_message}`}>
          {formik.errors.phone}
        </p>
      )}
      {isError && (
        <p className={`${loginStyles.login__invalid_message}`}>{loginError}</p>
      )}
      <Link
        to={"/restore_password"}
        className={loginStyles.login__restorePasswordLink}
      >
        שכחת את המספר משתמש שלך?
      </Link>
      <button
        type="submit"
        disabled={
          !formik.values.phone ||
          !formik.isValid ||
          formik.isSubmitting ||
          status === "pending"
        }
        className={`button ${loginStyles.login__button} ${
          !formik.values.phone ||
          !formik.isValid ||
          formik.isSubmitting ||
          status === "pending"
            ? loginStyles.login__button_disabled
            : "hover"
        }`}
      >
        {isLoading ? "שליחה" : "כניסה"}
      </button>
    </form>
  );
}

export default LoginSmsForm;
