import React, { useState } from "react";
import noDocksImg from "../../images/noDocs.png";
import myDocsStyles from "./myDocs.module.css";
import { getPrintDate } from "../../utils/utils";
import Modal from "../modals/modal/Modal";
import { IScannedDoc, TSendFileForm } from "../../types/types";
import ScannedDocumentPopup from "../modals/scannedDocument_success_popup/scannedDocument_success_popup";
import {
  useFetchDocksQuery,
  useSendFileMutation,
} from "../../services/api/user.api";
import {
  applicationDetails,
  docsCategories,
  sendFileFormInitialValue,
  urgencyVariants,
} from "../../utils/constants";

function MyDocs() {
  const [openModalScan, setOpenModalScan] = useState(false);
  const [toggle, setToggle] = useState({});
  const [scanned, setScanned] = useState<IScannedDoc[]>([]);
  const [showScannner, setShowscanner] = useState(false);
  const [form, setForm] = useState<TSendFileForm>(sendFileFormInitialValue);

  const { data, isError: isFetchDocksError } = useFetchDocksQuery();
  const [sendFile, { isError, isLoading }] = useSendFileMutation(); //to finish when server endpoints are ready

  const isDisabled =
    !form.details.value ||
    !form.urgent.value ||
    !form.file ||
    !form.category.value;
  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };
  const submitForm = () => {
    setOpenModalScan(true);
    setForm(sendFileFormInitialValue);
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      // sendFile(formData);
      setForm((prev) => ({ ...prev, file: formData }));
      setScanned([
        ...scanned,
        { name: file.name, date: new Date(), status: "בטיפול" },
      ]);
    }
  };

  return (
    <section className={myDocsStyles.section}>
      {openModalScan && (
        <Modal onClose={() => setOpenModalScan(false)}>
          <ScannedDocumentPopup onClose={() => setOpenModalScan(false)} />
        </Modal>
      )}
      <h2 className={myDocsStyles.section__title}>מסמכים</h2>
      {scanned.length === 0 && !showScannner ? (
        <>
          <img src={noDocksImg} alt={"מרשמים"} className={myDocsStyles.img} />
          <p className={myDocsStyles.noDocs}>לא נמצאו מסמכים</p>
          <button
            type={"button"}
            onClick={() => setShowscanner(true)}
            className={`button hover ${myDocsStyles.nextBtn}`}
          >
            העלאת מסמך חדש
          </button>
        </>
      ) : (
        <ul className={myDocsStyles.list}>
          <li
            className={`${myDocsStyles.li} ${
              toggle[1000 as keyof typeof setToggle] ? myDocsStyles.li_long : ""
            }`}
            onClick={() => toggleAnswer(1000)}
          >
            <p className={`${myDocsStyles.title}`}>העלאת מסמכים</p>
            <div className={myDocsStyles.scanSection}>
              <p className={myDocsStyles.scanTitle}>
                סריקת מסמכים
                <span className={myDocsStyles.scanIcon} />
              </p>
              <div className={myDocsStyles.scanOption}>
                <form className={myDocsStyles.form}>
                  <div
                    className={`button button_white ${myDocsStyles.btn} ${
                      myDocsStyles.category
                    } ${
                      toggle[999 as keyof typeof setToggle] &&
                      myDocsStyles.category_active
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleAnswer(999);
                    }}
                  >
                    {form.category.title}
                    <ul className={`${myDocsStyles.variants}`}>
                      {docsCategories.map(
                        (c) =>
                          c.value && (
                            <li
                              key={c.value}
                              className={myDocsStyles.variant}
                              onClick={(e) => {
                                e.stopPropagation();
                                setForm((prev) => ({
                                  ...prev,
                                  category: c,
                                }));
                                toggleAnswer(999);
                              }}
                            >
                              {c.title}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                  <div
                    className={`button button_white ${myDocsStyles.btn} ${
                      myDocsStyles.category
                    } ${
                      toggle[998 as keyof typeof setToggle] &&
                      myDocsStyles.category_active
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleAnswer(998);
                    }}
                  >
                    {form.details.title}
                    <ul className={`${myDocsStyles.variants}`}>
                      {applicationDetails.map(
                        (c) =>
                          c.value && (
                            <li
                              key={c.value}
                              className={myDocsStyles.variant}
                              onClick={(e) => {
                                e.stopPropagation();
                                setForm((prev) => ({
                                  ...prev,
                                  details: c,
                                }));
                                toggleAnswer(998);
                              }}
                            >
                              {c.title}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                  <div
                    className={`button button_white ${myDocsStyles.btn} ${
                      myDocsStyles.category
                    } ${
                      toggle[997 as keyof typeof setToggle] &&
                      myDocsStyles.category_active
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleAnswer(997);
                    }}
                  >
                    {form.urgent.title}
                    <ul className={`${myDocsStyles.variants}`}>
                      {urgencyVariants.map(
                        (c) =>
                          c.value && (
                            <li
                              key={c.value}
                              className={myDocsStyles.variant}
                              onClick={(e) => {
                                e.stopPropagation();
                                setForm((prev) => ({
                                  ...prev,
                                  urgent: c,
                                }));
                                toggleAnswer(997);
                              }}
                            >
                              {c.title}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                  <label
                    htmlFor={"icon-button-file"}
                    className={`button button_white ${myDocsStyles.btn} hover`}
                  >
                    {form.file ? "הקובץ הועלה בהצלחה  " : "סריקת מסמכים"}
                  </label>
                  <input
                    onChange={handleFileChange}
                    className={myDocsStyles.input}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                  />
                  <button
                    type={"submit"}
                    className={`button ${myDocsStyles.btn} ${
                      isDisabled && myDocsStyles.btn_disabled
                    }`}
                    disabled={isDisabled}
                    onClick={submitForm}
                  >
                    שלח
                  </button>
                </form>
                {(isError || isFetchDocksError) && !isLoading && (
                  <p className={`error`}>משהו השתבש, אנא נסה שוב מאוחר יותר</p>
                )}
              </div>
            </div>
          </li>
          <li
            className={`${myDocsStyles.li} ${
              toggle[1001 as keyof typeof setToggle] ? myDocsStyles.li_long : ""
            }`}
            onClick={() => toggleAnswer(1001)}
          >
            <p className={`${myDocsStyles.title}`}>העלאת מסמכים</p>

            <div className={myDocsStyles.scanSection}>
              <p className={myDocsStyles.scanTitle}>מסמכים שעלו</p>
              {scanned.length ? (
                <ul className={myDocsStyles.scannedList}>
                  {scanned.map((d, index) => (
                    <li key={index} className={myDocsStyles.scannedLi}>
                      <p className={myDocsStyles.label}>
                        שם מסמך:
                        <span className={myDocsStyles.info}>{d.name}</span>
                      </p>
                      <p className={myDocsStyles.label}>
                        תאריך העלאה:
                        <span className={myDocsStyles.info}>
                          {getPrintDate(d.date)}
                        </span>
                      </p>
                      <p className={myDocsStyles.label}>
                        סטטוס:
                        <span className={myDocsStyles.info}>{d.status}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={myDocsStyles.text}>תרם עלו מסמכים</p>
              )}
            </div>
          </li>
        </ul>
      )}
    </section>
  );
}

export default MyDocs;
