import accountPageStyles from "./goalsPage.module.css";
import Questionnaire from "../../components/questionnaire/questionnaire";
import { ISubCategory } from "../../types/types";
import MyAchievements from "../../components/myAchievements/myAchievements";
import MyPower from "../../components/myPower/myPower";
import MyProgress from "../../components/myProgress/myProgress";
import { subCategories } from "../../utils/categories";
import MyReports from "../../components/myReports/myReports";

function GoalsPage({ activeCategory }: { activeCategory: ISubCategory }) {
  const content = () => {
    const { value } = activeCategory;
    if (value === subCategories.achieved.value) {
      return <MyAchievements />;
    } else if (value === subCategories.progress.value) {
      return <MyProgress />;
    } else if (value === subCategories.reports.value) {
      return <MyReports />;
    } else if (value === subCategories.power.value) {
      return <MyPower />;
    } else return <MyAchievements />;
  };
  return (
    <main className={accountPageStyles.main}>
      <Questionnaire />
      {content()}
    </main>
  );
}

export default GoalsPage;
