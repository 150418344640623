import {
  Category,
  IPrevencherCategory,
  ISubCategory,
  ProfileCategories,
  SettingCategories,
  SubCategories,
} from "../types/types";
import venchy from "../images/questionnaire_doctor.png";
import diary from "../images/diary_icon.svg";
import recommendations from "../images/recommendations_icon.svg";

export const menuTitles: Record<Category, string> = {
  [Category.Day]: "היום שלי",
  [Category.Treatment]: "הטיפול שלי",
  [Category.Help]: "קבלת עזרה",
  [Category.Goals]: "תובנות ומטרות",
  [Category.Profile]: "הפרופיל שלי",
  [Category.Messages]: "הודעות",
  [Category.Contacts]: "צור קשר",
  [Category.Prevencher]: "פריוונצ’ר כאן בשבילך",
  [Category.Terms]: "תקנון ומדיניות פרטיות",
};
export const profileCategories: Record<ProfileCategories, ISubCategory> = {
  [ProfileCategories.Account]: {
    value: ProfileCategories.Account,
    title: "פרטי חשבון",
  },
  [ProfileCategories.Card]: {
    value: ProfileCategories.Card,
    title: "עדכון פרטי אשראי",
  },
  [ProfileCategories.Invoices]: {
    value: ProfileCategories.Invoices,
    title: "חשבוניות ותשלומים",
  },
  [ProfileCategories.Settings]: {
    value: ProfileCategories.Settings,
    title: "הגדרות",
  },
};

const {
  Treatment,
  Practice,
  Calendar,
  SupportGroup,
  Preferences,
  Docs,
  Account,
  Card,
  Invoices,
  Settings,
  Subscription,
  Support,
  Notifications,
  Faq,
  Coupon,
  Terms,
  Prescriptions,
  Achieved,
  Reports,
  Power,
  Progress,
} = SubCategories;

export const subCategories: Record<SubCategories, ISubCategory> = {
  [Account]: {
    value: Account,
    title: "פרטי חשבון",
  },
  [Card]: {
    value: Card,
    title: "עדכון פרטי אשראי",
  },
  [Invoices]: {
    value: Invoices,
    title: "חשבוניות ותשלומים",
  },
  [Settings]: {
    value: Settings,
    title: "הגדרות",
  },
  [Treatment]: {
    value: Treatment,
    title: "תכנית הטיפול שלי",
  },
  [SupportGroup]: {
    value: SupportGroup,
    title: "קבוצת התמיכה שלי",
  },
  [Practice]: {
    value: Practice,
    title: "התרגילים שלי",
  },
  [Docs]: {
    value: Docs,
    title: "מרשמים שלי",
  },
  [Calendar]: {
    value: Calendar,
    title: "יומן פגישות",
  },
  [Preferences]: {
    value: Preferences,
    title: "המועדפים שלי",
  },
  [Faq]: {
    value: Faq,
    title: "המועדפים שלי",
  },
  [Notifications]: {
    value: Notifications,
    title: "המועדפים שלי",
  },
  [Subscription]: {
    value: Subscription,
    title: "המועדפים שלי",
  },
  [Support]: {
    value: Support,
    title: "המועדפים שלי",
  },
  [Coupon]: {
    value: Coupon,
    title: "המועדפים שלי",
  },
  [Terms]: {
    value: Terms,
    title: "המועדפים שלי",
  },
  [Prescriptions]: {
    value: Prescriptions,
    title: "מרשמים שלי",
  },
  [Achieved]: {
    value: Achieved,
    title: "מה עשיתי החודש",
  },
  [Power]: {
    value: Power,
    title: "דירוג החוסן שלי",
  },
  [Reports]: {
    value: Reports,
    title: "דוחות ההתקדמות שלי",
  },
  [Progress]: {
    value: Progress,
    title: "התקדמות המטרות שלי",
  },
};

export const prevencherCategoris: IPrevencherCategory[] = [
  { value: "chat", img: venchy, title: "שיחה עם וונצ’י" },
  { value: "diary", img: diary, title: "יומן פריוונצ’ר" },
  {
    value: "recommendations",
    img: recommendations,
    title: "ההמלצות של וונצ’י",
  },
  { value: "acquaintance", img: venchy, title: "היכרות עם וונצ’י" },
];
export const settingCategories: ISubCategory[] = [
  {
    value: SettingCategories.Subscription,
    title: "תכנית המנוי שלנו",
  },
  {
    value: SettingCategories.Support,
    title: "תמיכה טכנית",
  },
  {
    value: SettingCategories.Notifications,
    title: "עדכונים והתראות",
  },
  {
    value: SettingCategories.Faq,
    title: "שאלות ותשובות",
  },
  {
    value: SettingCategories.Coupon,
    title: "קוד קופון",
  },
  {
    value: SettingCategories.Terms,
    title: "תקנון ומדיניות פרטיות",
  },
];

export interface IContactUsTheme {
  title: string;
  text: string;
}
export const reportsCategories: ISubCategory[] = [
  {
    value: "period",
    title: "דו''ח תקופתי",
  },
  {
    value: "summary",
    title: "סיכומי פגישות",
  },
];
