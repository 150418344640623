import mySubscriptionStyles from "./mySubscription.module.css";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useState } from "react";
import Modal from "../modals/modal/Modal";
import UpdateSubscriptionPopup from "../modals/updateSubscriptionSuccessPopup/updateSubscriptionPopup";
import { mobileWidth } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../services/api/user.api";
import {
  userPlanSelector,
  userSelector,
} from "../../services/selectors/user.selectors";

function MySubscription() {
  const [openModal, setOpenModal] = useState(false);

  const user = useAppSelector(userSelector);
  const plan = useAppSelector(userPlanSelector) ?? 1;
  const updateError =
    useAppSelector(errorUserSelector) ?? "משהו השתבש, אנא נסה שוב מאוחר יותר";

  const [changePlan, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();

  const updatePlan = async (id: number) => {
    const body = [
      {
        op: "replace",
        path: `/planId`,
        value: id.toString(),
      },
    ];
    changePlan({ body, id: user.id }).then((response) => {
      if ("data" in response && response.data) {
        setOpenModal(true);
        getUser();
      }
    });
  };

  const mobile = useMediaQuery(mobileWidth);

  return (
    <section className={mySubscriptionStyles.mySubscription}>
      <h2 className={mySubscriptionStyles.mySubscription__title}>
        תכנית המנוי שלנו
      </h2>
      <div className={mySubscriptionStyles.mySubscription__content}>
        {isError && (
          <p className={mySubscriptionStyles.mySubscription__errorMessage}>
            {updateError}
          </p>
        )}
        <ul className={mySubscriptionStyles.mySubscription__list}>
          <li>
            <article
              className={`${mySubscriptionStyles.mySubscription__variant} ${mySubscriptionStyles.mySubscription__variant_active}`}
            >
              {mobile && plan === 1 && (
                <button
                  type="button"
                  className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                />
              )}

              <div className={mySubscriptionStyles.mySubscription__header}>
                <p
                  className={`${mySubscriptionStyles.mySubscription__name} ${
                    mobile && mySubscriptionStyles.mySubscription__name_active
                  }`}
                >
                  מנוי בסיסי
                </p>
                <p className={mySubscriptionStyles.mySubscription__cost}>
                  חינם
                </p>
              </div>
              <p
                className={`${
                  mySubscriptionStyles.mySubscription__description
                } ${
                  mobile &&
                  mySubscriptionStyles.mySubscription__description_active
                }`}
              >
                גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה
                נפשית על ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם
                מתמודדים כמוך
              </p>
              {plan === 1 ? (
                mobile ? null : (
                  <button
                    type="button"
                    className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                  />
                )
              ) : (
                <button
                  type="button"
                  onClick={() => updatePlan(1)}
                  className={`${mySubscriptionStyles.mySubscription__btn} hover`}
                >
                  למנוי בסיסי
                </button>
              )}
            </article>
          </li>
          <li>
            <article className={mySubscriptionStyles.mySubscription__variant}>
              {mobile && plan === 2 && (
                <button
                  type="button"
                  className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                />
              )}
              <div className={mySubscriptionStyles.mySubscription__header}>
                <p className={mySubscriptionStyles.mySubscription__name}>
                  מנוי פרימיום
                </p>
                <p className={mySubscriptionStyles.mySubscription__cost}>
                  29 ש”ח לחודש
                </p>
              </div>
              <p className={mySubscriptionStyles.mySubscription__description}>
                גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה
                נפשית על ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם
                מתמודדים כמוך, ועוד
              </p>
              {plan === 2 ? (
                mobile ? null : (
                  <button
                    type="button"
                    className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                  />
                )
              ) : (
                <button
                  type="button"
                  onClick={() => updatePlan(2)}
                  className={`${mySubscriptionStyles.mySubscription__btn} hover`}
                >
                  שדרג לפרימיום
                </button>
              )}
            </article>
          </li>
          <li>
            <article className={mySubscriptionStyles.mySubscription__variant}>
              {mobile && plan === 3 && (
                <button
                  type="button"
                  className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                />
              )}
              <div className={mySubscriptionStyles.mySubscription__header}>
                <p className={mySubscriptionStyles.mySubscription__name}>
                  מנוי פרימיום פלוס
                </p>
                <p className={mySubscriptionStyles.mySubscription__cost}>
                  59 ש”ח לחודש
                </p>
              </div>
              <p className={mySubscriptionStyles.mySubscription__description}>
                גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה
                נפשית על ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם
                מתמודדים כמוך, ועוד
              </p>
              {plan === 3 ? (
                mobile ? null : (
                  <button
                    type="button"
                    className={`${mySubscriptionStyles.mySubscription__btn_done} hover`}
                  />
                )
              ) : (
                <button
                  type="button"
                  onClick={() => updatePlan(3)}
                  className={`${mySubscriptionStyles.mySubscription__btn} hover`}
                >
                  שדרג לפרימיום פלוס
                </button>
              )}
            </article>
          </li>
        </ul>
      </div>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <UpdateSubscriptionPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}
    </section>
  );
}

export default MySubscription;
