import {
  IUserResponse,
  TGetUserCurrentFlowRequest,
  TGetUserCurrentFlowResponse,
} from "../types/auth.types";
import {
  TCancelAppointmentRequest,
  TGetQuestionByIdResponse,
  TInsertAnswerRequest,
  TUpdateUserRequest,
  TUpdateUserSettingsRequest,
  TUserTreatmentResponse,
} from "../types/user.types";
import { api } from "./api";
import { IResponse } from "../types";
import {
  TMakeAppointmentRequest,
  TUpdateAppointmentRequest,
} from "../types/user.types";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, void>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
    }),
    getUsers: build.query<IUserResponse, string>({
      query: () => ({
        url: `user/testusers`,
        method: "GET",
      }),
    }),
    getCurrentFlow: build.query<
      TGetUserCurrentFlowResponse,
      TGetUserCurrentFlowRequest
    >({
      query: ({ usersFlowId, userId }) => ({
        url: `/Questions/GetUserCurrentFlow/${usersFlowId}/${userId}`,
        method: "GET",
      }),
    }),
    updateUser: build.mutation<IUserResponse, TUpdateUserRequest>({
      query: ({ body, id }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    updateUserSettings: build.mutation<
      IUserResponse,
      TUpdateUserSettingsRequest
    >({
      query: ({ body, id }) => ({
        url: `user/settings/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getUserTreatment: build.query<TUserTreatmentResponse, string>({
      query: () => ({
        url: `/users/treatment`,
        method: "GET",
      }),
    }),
    makeAppointment: build.mutation<IResponse, TMakeAppointmentRequest>({
      query: ({ date, therapistId }) => ({
        url: `/users/appointments`,
        method: "POST",
        body: { therapistId, date },
      }),
    }),
    updateAppointment: build.mutation<IResponse, TUpdateAppointmentRequest>({
      query: (body) => ({
        url: `/users/appointments`,
        method: "Patch",
        body,
      }),
    }),
    cancelAppointment: build.mutation<IResponse, TCancelAppointmentRequest>({
      query: (body) => ({
        url: `/users/appointments`,
        method: "DELETE",
        body,
      }),
    }),
    fetchDocks: build.query<IResponse, void>({
      query: () => ({
        url: `/users/docs`,
        method: "GET",
      }),
    }),
    sendFile: build.mutation<IResponse, FormData>({
      query: (body) => ({
        url: `/users/docs`,
        method: "POST",
        body,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    fetchPrescriptions: build.query<IResponse, void>({
      query: () => ({
        url: `/users/prescriptions`,
        method: "GET",
      }),
    }),
    updatePrescription: build.mutation<IResponse, string>({
      query: (prescriptionId) => ({
        url: `/users/prescriptions`,
        method: "POST",
        body: { prescriptionId },
      }),
    }),
    getQuestionByQuestionnaireId: build.query<
      TGetQuestionByIdResponse,
      { currentQuestionId: number; nextQuestionId: number; skip: boolean }
    >({
      query: ({ currentQuestionId, nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/${currentQuestionId}/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    getPOCQuestionById: build.query<
      TGetQuestionByIdResponse,
      { nextQuestionId: number; skip: boolean }
    >({
      query: ({ nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/26/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    getNextQuestionByQuestionnaireId: build.query<
      TGetQuestionByIdResponse,
      { currentQuestionId: number; nextQuestionId: number; skip: boolean }
    >({
      query: ({ currentQuestionId, nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/${currentQuestionId}/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    insertAnswerQuestion: build.mutation<IResponse, TInsertAnswerRequest>({
      query: (option) => ({
        url: `/Questions/InsertAnswerQuestion`,
        method: "POST",
        body: JSON.stringify(option),
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useGetQuestionByQuestionnaireIdQuery,
  useLazyGetQuestionByQuestionnaireIdQuery,
  useLazyGetPOCQuestionByIdQuery,
  useGetUserTreatmentQuery,
  useLazyGetCurrentFlowQuery,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
  useMakeAppointmentMutation,
  useUpdateAppointmentMutation,
  useCancelAppointmentMutation,
  useFetchDocksQuery,
  useSendFileMutation,
  useFetchPrescriptionsQuery,
  useUpdatePrescriptionMutation,
  useGetCurrentFlowQuery,
  useInsertAnswerQuestionMutation,
  useLazyGetNextQuestionByQuestionnaireIdQuery,
} = userApi;
