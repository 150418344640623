import mySettingsStyles from "./mySettings.module.css";
import { useEffect, useState } from "react";
import { SettingCategories, TLocationState } from "../../types/types";
import MySubscription from "../mySubscription/mySubscription";
import MyNotifications from "../myNotifications/myNotifications";
import Faq from "../faq/faq";
import Support from "../support/support";
import { useLocation } from "react-router-dom";
import { settingCategories } from "../../utils/categories";
import Terms from "../terms/terms";
import Coupon from "../coupon/coupon";
import { customerService } from "../../utils/constants";

function MySettings() {
  const [category, setCategory] = useState("");
  const location = useLocation() as TLocationState;
  const [supportType, setSupportType] = useState("tech");
  useEffect(() => {
    if (location.state?.pathname === "/treatment") {
      setCategory(SettingCategories.Subscription);
    } else if (location.state?.pathname === "techSupport") {
      setCategory(SettingCategories.Support);
    } else if (location.state?.pathname === customerService) {
      setSupportType(customerService);
      setCategory(SettingCategories.Support);
    } else if (location.state?.pathname === "terms") {
      setCategory(SettingCategories.Terms);
    }
  }, []);

  return (
    <section className={mySettingsStyles.mySettings}>
      {!category && (
        <>
          <h2 className={mySettingsStyles.mySettings__title}>תפריט הגדרות</h2>
          <ul className={mySettingsStyles.mySettings__list}>
            {settingCategories.map((category, index) => (
              <li key={index} className={mySettingsStyles.mySettings__li}>
                <button
                  type={"button"}
                  onClick={() => setCategory(category.value)}
                  className={`${mySettingsStyles.mySettings__btn} hover`}
                >
                  {category.title}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
      {category === SettingCategories.Subscription && <MySubscription />}
      {category === SettingCategories.Notifications && <MyNotifications />}
      {category === SettingCategories.Faq && <Faq />}
      {category === SettingCategories.Support && <Support type={supportType} />}
      {category === SettingCategories.Terms && <Terms />}
      {category === SettingCategories.Coupon && <Coupon />}
    </section>
  );
}

export default MySettings;
