import OnboardingLayout from "../../components/onboardingLayout/onboardingLayout";
import Urgent from "../../components/urgent/urgent";
import singinStyles from "./signin.module.css";
import SigninForm1 from "../../components/forms/signin/signinForm1";
import { useState } from "react";
import SigninForm2 from "../../components/forms/signin/signinForm2";
import CreatePasswordForm from "../../components/forms/signin/createPasswordForm";
import { useNavigate } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { IRegisterRequest } from "../../services/types/auth.types";
import { existingUserFlowId } from "../../utils/constants";

function SigninPrivatePage() {
  const navigate = useNavigate();

  const [state, setState] = useState<IRegisterRequest>({
    usersFlowId: existingUserFlowId,
    firstName: "",
    lastName: "",
    passport: "",
    birth: "",
    phone: "",
    email: "",
    password: "",
    genderId: 1,
    country: "ישראל",
  });
  const [part, setPart] = useState(1);

  const changeForm = (num: number) => {
    setPart(num);
  };
  const goBack = () => {
    if (part === 1) {
      navigate("/signin");
      return;
    } else if (part === 2 || part === 3) {
      setPart((prev) => prev - 1);
      return;
    }
  };
  return (
    <OnboardingLayout>
      <article className={`${singinStyles.signin}`}>
        <BackArrowButton onClick={goBack} />{" "}
        <h1 className={singinStyles.signin__title}>
          {part === 3 ? "יצירת סיסמה" : "הרשמה"}
        </h1>
        <p
          className={`${singinStyles.signin__text} ${
            part === 3 && singinStyles.signin__text_small
          }`}
        >
          {part === 3
            ? "צור סיסמה באורך של לפחות 6 תווים, שילוב של אותיות, מספרים וסמלים"
            : "דרך חשבון פרטי"}
        </p>
        {part === 1 && (
          <SigninForm1
            state={state}
            setState={setState}
            changeForm={() => changeForm(2)}
          />
        )}
        {part === 2 && (
          <SigninForm2
            state={state}
            setState={setState}
            changeForm={() => changeForm(3)}
          />
        )}
        {part === 3 && (
          <CreatePasswordForm
            state={state}
            setState={setState}
            changeForm={() => changeForm(3)}
          />
        )}
        <Urgent />
      </article>
    </OnboardingLayout>
  );
}

export default SigninPrivatePage;
