import { createSlice } from "@reduxjs/toolkit";
import { getRandomDatesWithTimesOfCurrentMonth } from "../../utils/getRandomDates";
import { TTherapistsState } from "../types/therapists.types";
import { isSameDateAndTime } from "../../utils/utils";

const initialState: TTherapistsState = {
  therapists: [],
  availableDates: [],
  myTreatmentText: "",
};

export const therapistsSlice = createSlice({
  name: "therapists",
  initialState,
  reducers: {
    setDates: (state) => {
      return {
        ...state,
        availableDates: getRandomDatesWithTimesOfCurrentMonth(),
      };
    },
    removeDate: (state, { payload }) => {
      return {
        ...state,
        availableDates: state.availableDates.filter(
          (date) => !isSameDateAndTime(date, payload)
        ),
      };
    },
  },
});

export const { actions, reducer } = therapistsSlice;
export default therapistsSlice.reducer;
