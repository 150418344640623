import { IMessage, ITherapist } from "../../types/types";
import messageStyles from "./message.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { getTimeFromTimestamp } from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { testData } from "../../utils/user";
import { Link } from "react-router-dom";

function Message({
  message,
  isOwnMessage,
  therapist,
}: {
  therapist: Partial<ITherapist>;
  message: IMessage;
  isOwnMessage: boolean;
}) {
  const messageClassname = isOwnMessage
    ? messageStyles.ownMessage
    : messageStyles.message;
  const containerClassname = isOwnMessage
    ? messageStyles.ownMessageContainer
    : messageStyles.messageContainer;

  const user = useAppSelector(userSelector);
  const testUser = {
    ...testData,
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
  };

  const avatar = isOwnMessage ? testUser.avatar : therapist.avatar;
  const alt = isOwnMessage ? testUser.firstName : therapist.name;
  const linkRegex = /@link/g;

  return (
    <>
      <div className={containerClassname}>
        <img
          src={avatar ? avatar : avatarPlaceholder}
          alt={alt}
          className={messageStyles.avatar}
        />
        <div className={messageClassname}>
          {message.message.includes("@link") ? (
            <p className={messageStyles.text}>
              {message.message.replace(linkRegex, "")}{" "}
              <Link className={messageStyles.link} to={"/treatment"}
                state={{pathname: "videos"}}>
                לסרטונים
              </Link>
            </p>
          ) : (
            <p className={messageStyles.text}>{message.message}</p>
          )}
          <p
            className={`${messageStyles.time} ${
              isOwnMessage && messageStyles.time_ownMessage
            }`}
          >
            {getTimeFromTimestamp(message.date)}
          </p>
        </div>
      </div>
    </>
  );
}

export default Message;
