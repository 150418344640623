import { ITherapist } from "../../types/types";
import therapistStyles from "./therapist.module.css";
import { charMax, tabletWidth } from "../../utils/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useLazyGetTherapistDatesQuery } from "../../services/api/therapists.api";
import { useActions } from "../../services/hooks/useActions";

interface ITherapistProps {
  therapist: ITherapist;
  onClose: () => void;
  setShowCalendar: (a: boolean) => void;
}
function Therapist({ therapist, onClose, setShowCalendar }: ITherapistProps) {
  const [full, setFull] = useState(false);
  const navigate = useNavigate();
  const { setDates } = useActions();

  const isTablet = useMediaQuery(tabletWidth);

  const [fetchDates, { data, isError }] = useLazyGetTherapistDatesQuery();

  return (
    <section className={therapistStyles.therapist}>
      <div className={therapistStyles.infoContainer}>
        <article className={therapistStyles.card}>
          <img
            src={therapist.avatar}
            alt={therapist.name}
            className={therapistStyles.card__avatar}
          />
          <p className={therapistStyles.card__name}>{therapist.name}</p>
          <p className={therapistStyles.card__status}>{therapist.status}</p>
          <p className={therapistStyles.card__tag}>המטפל שלי</p>
          <ul className={therapistStyles.card__connectionBar}>
            <li
              onClick={() => navigate("/chat")}
              className={`${therapistStyles.card__connectionBarLi} ${therapistStyles.card__connectionBarLi_chat} ${therapistStyles.card__connectionBarLi_active}`}
            />
            <li
              className={`${therapistStyles.card__connectionBarLi} ${therapistStyles.card__connectionBarLi_phone}`}
            />
            <li
              className={`${therapistStyles.card__connectionBarLi} ${therapistStyles.card__connectionBarLi_video} ${therapistStyles.card__connectionBarLi}`}
            />
          </ul>
          <button
            className={therapistStyles.card__btn}
            onClick={() => {
              fetchDates(therapist.id);
              setDates();
              setShowCalendar(true);
            }}
          >
            קבע פגישה
          </button>
        </article>
        <div className={therapistStyles.therapist__info}>
          {isTablet && (
            <h3 className={therapistStyles.therapist__aboutTitle}>קצת עליי</h3>
          )}
          <video
            className={therapistStyles.therapist__video}
            src={therapist.video}
            controls={true}
          />
          {/*{isTablet && <Pagination />}*/}
          <div className={therapistStyles.therapist__about}>
            {!isTablet && (
              <h3 className={therapistStyles.therapist__aboutTitle}>
                קצת עליי
              </h3>
            )}
            <p
              className={`${therapistStyles.therapist__text} ${
                full ? therapistStyles.therapist__text_full : ""
              }`}
            >
              {therapist.about}
            </p>
            {therapist.about.length > charMax && (
              <button
                className={therapistStyles.therapist__moreBtn}
                onClick={() => setFull(!full)}
              >
                {full ? "קרא פחות" : "קרא עוד"}
              </button>
            )}
          </div>
        </div>
      </div>
      <button className={therapistStyles.therapist__closeBtn} onClick={onClose}>
        חזרה לחיפוש
      </button>
    </section>
  );
}

export default Therapist;
