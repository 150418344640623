import accountPageStyles from "./treatmentPage.module.css";
import Questionnaire from "../../components/questionnaire/questionnaire";
import {ISubCategory, TLocationState} from "../../types/types";
import MyTreatment from "../../components/myTreatment/myTreatment";
import MyCalendar from "../../components/myCalendar/myCalendar";
import MyPractice from "../../components/myPractice/myPractice";
import MyDocs from "../../components/myDocs/myDocs";
import { subCategories } from "../../utils/categories";
import MyPrescriptions from "../../components/myPrescriptions/myPrescriptions";
import {useLocation} from "react-router-dom";

function TreatmentPage({ activeCategory }: { activeCategory: ISubCategory }) {
  const location = useLocation() as TLocationState;

  const content = () => {
    if (activeCategory === subCategories.calendar) {
      return <MyCalendar />;
    } else if (activeCategory === subCategories.practice) {
      return <MyPractice />;
    } else if (activeCategory === subCategories.docs) {
      return <MyDocs />;
    } else if (activeCategory === subCategories.prescriptions) {
      return <MyPrescriptions />;
    } else return <MyTreatment />;
  };

  return (
    <main className={accountPageStyles.main}>
      <Questionnaire />
      {location?.state?.pathname === "videos" ?
          <MyPractice /> :
          content()}
    </main>
  );
}

export default TreatmentPage;
