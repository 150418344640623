import couponStyles from "./coupon.module.css";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import SupportSuccessPopup from "../modals/support_success_popup/support_success_popup";
import { useSendCouponMutation } from "../../services/api/requests.api";
import { useAppSelector } from "../../services/hooks";
import { errorRequestNumberSelector } from "../../services/selectors/requests.selectors";
import { userSelector } from "../../services/selectors/user.selectors";

function Coupon() {
  const [state, setState] = useState({ coupon: "" });
  const [openModal, setOpenModal] = useState(false);

  const [sendCoupon, { isError }] = useSendCouponMutation();
  const updateError =
    useAppSelector(errorRequestNumberSelector) ??
    "משהו השתבש, אנא נסה שוב מאוחר יותר";
  const user = useAppSelector(userSelector);

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    sendCoupon({ coupon: state.coupon, id: user.id }).then((response) => {
      if ("data" in response && response.data) {
        // getUser("");
      }
    });
    setOpenModal(true);
    setState({ coupon: "" });
  };
  return (
    <section className={couponStyles.coupon}>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <SupportSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}

      <h2
        className={`${couponStyles.coupon__title} ${couponStyles.coupon__title_main}`}
      >
        קוד קופון
      </h2>
      <h3 className={couponStyles.coupon__title}>יש לכם קוד קופון?</h3>
      <p className={couponStyles.coupon__text}>
        גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על
        ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועוד
      </p>
      <form className={couponStyles.coupon__form} onSubmit={submitForm}>
        <label htmlFor="theme" className={couponStyles.coupon__label}>
          להזין קוד קופון
        </label>
        <input
          value={state.coupon}
          type="text"
          name={"coupon"}
          id="coupon"
          className={couponStyles.coupon__input}
          autoFocus={true}
          onChange={handleChange}
        />
        {isError && <p className={"error"}>{updateError}</p>}
        <button
          type="submit"
          disabled={!state.coupon}
          className={`button ${couponStyles.coupon__btn} ${
            !state.coupon ? couponStyles.coupon__btn_disabled : "hover"
          }`}
        >
          שליחת פניה
        </button>
      </form>
    </section>
  );
}

export default Coupon;
