import mainContentLayoutStyles from "../../components/mainContentLayout/mainContentLayout.module.css";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { mobileWidth } from "../../utils/constants";
import Sidebar from "../../components/sidebar/sidebar";
import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Chat from "../../components/chat/chat";
import { Category } from "../../types/types";
import Notifications from "../../components/notifications/notifications";
import { menuTitles } from "../../utils/categories";
import { chatTherapists } from "../../utils/therapists";
// import { getMessages, getUser, getWsConnected } from './redux/selectors';
// import { useDispatch, useSelector } from 'react-redux';
// import { wsSendMessage } from './redux/actions';
// import { WS_CONNECTION_START } from './redux/action-types';
// import { joinChat } from './redux/thunks';

interface IChatPageProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function ChatPage({ activeCategory, changeCategory }: IChatPageProps) {
  const [therapist, setTherapist] = useState(chatTherapists[0]);
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const mobile = useMediaQuery(mobileWidth);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  //!!to uncomment when redux and ws connection are ready

  // const dispatch = useDispatch();
  // const message = useSelector(getMessages);
  // const { user } = useSelector(getUser);
  // const isConnected = useSelector(getWsConnected);
  // useEffect(
  //     () => {
  //         if (user) {
  //             dispatch({ type: WS_CONNECTION_START });
  //         }
  //     },
  //     [user] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  // useEffect(() => {
  //     dispatch(joinChat());
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const scrollToBottom = useCallback(
  //     () => {
  //         if (messagesContainerRef.current) {
  //             messagesContainerRef.current.scrollTo(0, messagesContainerRef.current.scrollHeight);
  //         }
  //     },
  //     [messagesContainerRef]
  // );

  // useEffect(
  //     () => {
  //         scrollToBottom();
  //     },
  //     [message, scrollToBottom]
  // );

  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mainContentLayoutStyles.main_chat
      } ${!openMenu && mainContentLayoutStyles.main_chat_small}`}
    >
      <Notifications showNotifications={showNotifications} />
      <div
        className={`${mainContentLayoutStyles.overlay} ${
          mobile && openMenu ? mainContentLayoutStyles.overlay_visible : ""
        }`}
      />
      <section
        className={`${mainContentLayoutStyles.mainContent} ${mainContentLayoutStyles.mainContent_chat}`}
        onClick={hideNotifications}
      >
        <div
          className={`${mainContentLayoutStyles.header} ${mainContentLayoutStyles.header_chat}`}
        >
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {menuTitles[activeCategory]}
          </h1>
          <button
            className={`${mainContentLayoutStyles.mainContent__bell} ${
              showNotifications &&
              mainContentLayoutStyles.mainContent__bell_invisible
            }`}
            onClick={() => {
              setShowNotifications(!showNotifications);
            }}
          />
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        <Chat
          setTherapist={setTherapist}
          therapist={therapist}
          openMenu={openMenu}
        />
      </section>
      {!mobile && (
        <ChatSideBar
          allTherapists={chatTherapists}
          setTherapist={setTherapist}
          openMenu={openMenu}
          therapist={therapist}
        />
      )}
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={activeCategory}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
    </main>
  );
}

export default ChatPage;
