import changePasswordPopupStyles from "./changePasswordPopup.module.css";
import React, { useEffect, useState } from "react";
import { IPopupWithoutProps } from "../../../types/types";
import { passwordCheck } from "../../../utils/utils";
import ChangePasswordSuccessPopup from "../changePasswordSuccessPopup/changePasswordSuccessPopup";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../../services/api/user.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { userSelector } from "../../../services/selectors/user.selectors";

const initialState = { password: "", newPassword: "" };
const minLength = 6;

function ChangePasswordPopup({ onClose }: IPopupWithoutProps) {
  const [state, setState] = useState(initialState);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [updateUser, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();
  const updateError = useAppSelector(errorUserSelector);
  const user = useAppSelector(userSelector);

  const disabled =
    state.password.length < minLength || state.newPassword.length < minLength;

  useEffect(() => {
    setError("");
    if (
      (state.password.length > 0 && state.password.length < 6) ||
      (state.newPassword.length > 0 && state.newPassword.length < 6)
    ) {
      setError("הסיסמה חייבת להיות לפחות 6 תווים");
    }
  }, [state]);

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (state.password !== user.password) {
      setError("הסיסמה לא נכונה");
      return;
    }
    const response = await updateUser({
      body: [
        {
          op: "replace",
          path: `/password`,
          value: state.newPassword,
        },
      ],
      id: user.id,
    });
    if ("data" in response && response.data) {
      setOpenModal(true);
      getUser();
    }
    setState(initialState);
  };

  return openModal ? (
    <ChangePasswordSuccessPopup onClose={onClose} />
  ) : (
    <article className={changePasswordPopupStyles.popup}>
      <div className={changePasswordPopupStyles.popup__content}>
        <button
          type="button"
          onClick={onClose}
          className={changePasswordPopupStyles.popup__closeBtn}
        />
        <h2 className={changePasswordPopupStyles.popup__title}>שינוי סיסמה</h2>
        <form
          onSubmit={submitForm}
          className={changePasswordPopupStyles.popup__form}
        >
          <label
            htmlFor={"currentPassword"}
            className={changePasswordPopupStyles.popup__label}
          >
            סיסמא נוכחית
          </label>
          <input
            type="password"
            name={"password"}
            id={"password"}
            placeholder={"סיסמא נוכחית"}
            value={state.password}
            onChange={handleChange}
            required={true}
            minLength={minLength}
            className={changePasswordPopupStyles.popup__input}
          />
          <label
            htmlFor={"newPassword"}
            className={changePasswordPopupStyles.popup__label}
          >
            סיסמא חדשה
          </label>
          <input
            type="password"
            name={"newPassword"}
            id={"newPassword"}
            value={state.newPassword}
            onChange={handleChange}
            placeholder={"סיסמא חדשה"}
            required={true}
            minLength={minLength}
            className={changePasswordPopupStyles.popup__input}
          />
          {state.password && (
            <>
              <ul className={changePasswordPopupStyles.popup__list}>
                {[1, 2, 3].map((level) => (
                  <li
                    key={level}
                    className={`${changePasswordPopupStyles.popup__li} ${
                      level <= passwordCheck(state.newPassword).strength &&
                      changePasswordPopupStyles.popup__li_active
                    }`}
                  />
                ))}
              </ul>
              <p className={changePasswordPopupStyles.popup__strength}>
                חוזק סיסמא: {passwordCheck(state.password).val}
              </p>
            </>
          )}
          <p className={changePasswordPopupStyles.popup__text}>
            הגדרת סיסמא חדשה: באורך של לפחות 6 תווים אבל עדיף 8 או יותר. שילוב
            של אותיות, מספרים וסמלים.
          </p>
          {error && (
            <p className={changePasswordPopupStyles.popup__invalid_message}>
              {error}
            </p>
          )}
          {isError && (
            <p className={changePasswordPopupStyles.popup__invalid_message}>
              {updateError}
            </p>
          )}

          <button
            type="submit"
            disabled={disabled}
            className={`${changePasswordPopupStyles.popup__submitBtn} ${
              disabled
                ? changePasswordPopupStyles.popup__submitBtn_disabled
                : ""
            }`}
          >
            לשינוי הסיסמה
          </button>
        </form>
      </div>
    </article>
  );
}

export default ChangePasswordPopup;
