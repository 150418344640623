import { IAppointment, IAppointmentDay, IUser } from "../types/types";

export function getDate(date: string) {
  const messageDate = new Date(date);
  const now = new Date();
  const messageYear = messageDate.getFullYear();
  const messageMonth = messageDate.getMonth();
  const messageDay = messageDate.getDate();
  const yearNow = now.getFullYear();
  const monthNow = now.getMonth();
  const dayNow = now.getDate();
  if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    messageDay === dayNow
  ) {
    return "היום";
  } else if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    dayNow - messageDay === 1
  ) {
    return "אתמול";
  }
  return `${messageDay}/${messageMonth}/${messageYear}`;
}

const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "נובמבר",
];
export const getMonth = (date: Date) => {
  const month = date.getMonth();
  return months[month];
};

function formatNumberWithLeadingZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function getDuration(date: Date, minutesToAdd: number): string {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutesToAdd);
  return `${formatNumberWithLeadingZero(
    date.getHours()
  )}:${formatNumberWithLeadingZero(
    date.getMinutes()
  )}-${formatNumberWithLeadingZero(
    newDate.getHours()
  )}:${formatNumberWithLeadingZero(newDate.getMinutes())}`;
}
export const getTimeFromTimestamp = (date: Date) => {
  const timestamp = date.toTimeString().split(":");
  return `${timestamp[0]}:${timestamp[1]}`;
};

export function getPrintDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function isExpired(date: Date): boolean {
  const now = new Date();
  return date < now;
}
export function groupAppointmentsByDate(
  appointments: IAppointment[]
): IAppointmentDay[] {
  const groups: any = appointments?.reduce((groups, a) => {
    const date = a.date.toDateString();
    // @ts-ignore
    if (!groups[date]) {
      // @ts-ignore
      groups[date] = [];
    }
    // @ts-ignore
    groups[date].push(a);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  return Object.keys(groups).map((date) => {
    return {
      date,
      appointments: groups[date],
    };
  });
}
export const passwordCheck = (
  value: string
): {
  strength: number;
  val: string;
} => {
  let pwdCheck = 0;
  let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];

  value.length > 5 &&
    validateRegex.forEach((regex) => {
      if (new RegExp(regex).test(value)) {
        pwdCheck += 1;
      }
    });
  switch (pwdCheck) {
    case 2:
      return {
        strength: 2,
        val: "בינוני",
      };
    case 3:
      return {
        strength: 3,
        val: "חזק",
      };

    default:
      return {
        strength: 1,
        val: "חלש",
      };
  }
};

export function lowercaseKeys(obj: object): object {
  return Object.entries(obj).reduce((carry, [key, value]) => {
    // @ts-ignore
    carry[key.charAt(0).toLowerCase() + key.slice(1)] = value;
    return carry;
  }, {});
}

type ChangedValues = Partial<IUser>;

export const getChangedValues = (
  values: IUser,
  initialValues: IUser
): ChangedValues => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key as keyof IUser] = value;
    }

    return acc;
  }, {} as ChangedValues);
};

export function isSameDateAndTime(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes()
  );
}

export function replaceVariables(text: string, userData: IUser): string {
  if (!text || !userData) return "";
  const userName = userData.firstName;
  const userGender = userData.genderId;
  // Replace @name with user name
  let replacedString = text.replace(/(@name|name@)/gi, userName);
  if (text.toLowerCase().includes("gender")) {
    replacedString = replacedString.replace(
      /(@gender_know|gender_know@)/gi,
      userGender === "נקבה" ? "יודעת" : "יודע"
    );
    replacedString = replacedString.replace(
      /(@gender_ready|gender_ready@)/gi,
      userGender === "נקבה" ? "מוכנה" : "מוכן"
    );
    replacedString = replacedString.replace(
      /(@gender|gender@)/gi,
      userGender === "נקבה" ? "את" : "אתה"
    );
  }

  return replacedString;
}
