import { api } from "./api";
import {
  TGetTherapistsResponse,
  TGetDatesResponse,
} from "../types/therapists.types";

export const therapistsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFields: build.query<string[], void>({
      query: () => ({
        url: `therapists`,
        method: "GET",
      }),
    }),
    getTherapists: build.query<TGetTherapistsResponse, number>({
      query: (field) => ({
        url: `therapists/${field}`,
        method: "GET",
      }),
    }),
    getTherapistDates: build.query<TGetDatesResponse, string>({
      query: (id) => ({
        url: `therapists/therapist/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetFieldsQuery,
  useLazyGetTherapistsQuery,
  useLazyGetTherapistDatesQuery,
} = therapistsApi;
