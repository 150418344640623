import myTreatmentStyles from "./myTreatment.module.css";
import { useState } from "react";
import { ITherapist } from "../../types/types";
import Therapist from "../therapist/therapist";
import MakeAppointment from "../makeAppointment/makeAppointment";
import TherapistCard from "../therapistCard/therapistCard";
import { useGetUserTreatmentQuery } from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import NoAppointments from "../noAppointments/noAppointments";
import {
  userSelector,
  userTherapistsSelector,
} from "../../services/selectors/user.selectors";
import { useNavigate } from "react-router-dom";

function MyTreatment() {
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState<ITherapist | undefined>(undefined);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const user = useAppSelector(userSelector);
  const userTherapists = useAppSelector(userTherapistsSelector);

  const { data } = useGetUserTreatmentQuery("", {
    skip: !!user.myTreatmentText,
  });
  if (showSearch) return <NoAppointments />;

  return (
    <section className={myTreatmentStyles.myTreatment}>
      <h2 className={myTreatmentStyles.myTreatment__title}>
        {!therapist
          ? "תכנית הטיפול שלי"
          : showCalendar
          ? "הפגישות שלי"
          : "המטפל שלי"}
      </h2>
      {!therapist ? (
        <>
          <div className={myTreatmentStyles.myTreatment__info}>
            {user.myTreatmentText ? (
              <p className={myTreatmentStyles.myTreatment__text}>
                {user.myTreatmentText}
              </p>
            ) : (
              <>
                <p className={myTreatmentStyles.myTreatment__text}>
                  לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס
                  מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית
                  קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק.
                  תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג -
                  ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.
                  הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית
                  נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי
                  שהכים תוק, הדש שנרא התידם הכייר וק.
                </p>
                <p className={myTreatmentStyles.myTreatment__text}>
                  הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית
                  נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי
                  שהכים תוק, הדש שנרא התידם הכייר וק.
                </p>
              </>
            )}
          </div>
          <h3 className={myTreatmentStyles.myTreatment__listTitle}>
            המטפלים שלי
          </h3>
          {userTherapists?.length ? (
            <>
              <ul className={myTreatmentStyles.myTreatment__list}>
                {userTherapists.map((t, index) => (
                  <TherapistCard
                    therapist={t}
                    setTherapist={setTherapist}
                    key={index}
                  />
                ))}
              </ul>
              <button
                className={`button hover ${myTreatmentStyles.btn}`}
                onClick={() => navigate("search")}
              >
                לבחור מטפל נוסף
              </button>
            </>
          ) : (
            <>
              <p
                className={`${myTreatmentStyles.myTreatment__text} ${myTreatmentStyles.myTreatment__text_small}`}
              >
                טרם נקבע לך מטפל
              </p>
              <button
                className={`button hover ${myTreatmentStyles.btn}`}
                // onClick={() => setShowSearch(true)}
                onClick={() => navigate("search")}
              >
                לבחור מטפל
              </button>
            </>
          )}
        </>
      ) : showCalendar && therapist ? (
        <MakeAppointment therapist={therapist} />
      ) : (
        <Therapist
          therapist={therapist}
          onClose={() => setTherapist(undefined)}
          setShowCalendar={setShowCalendar}
        />
      )}
    </section>
  );
}
export default MyTreatment;
