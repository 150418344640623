import popupStyles from "./cookies_popup.module.css";
import { IPopupWithoutProps } from "../../../types/types";

function SignupSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <button
        className={`${popupStyles.popup__closeBtn} hover`}
        onClick={onClose}
      />
      <h2 className={popupStyles.popup__title}>ברוך הבא!</h2>
      <p className={popupStyles.popup__text}>אנחנו לא אוהבים לשמור בבטן :)</p>
      <p className={popupStyles.popup__text}>
        אנחנו משתמשים בקבצי Coockie על מנת להעניק לך חוויה טובה יותר.
      </p>
      <p className={popupStyles.popup__text}>
        בלחיצה על “מסכימ/ה להכל” אתה מסכים לשימוש בכל העוגיות.
      </p>
      <p className={popupStyles.popup__text}>
        אתה יכול גם לערוך הגדרות Coockie
      </p>
      <button
        className={`button ${popupStyles.popup__nextBtn} hover`}
        onClick={onClose}
      >
        אני מסכים/ה להכל
      </button>
      <button
        className={`button ${popupStyles.popup__nextBtn} ${popupStyles.popup__nextBtn_white} hover`}
        onClick={onClose}
      >
        הגדרות Cookie
      </button>
    </article>
  );
}

export default SignupSuccessPopup;
