import React, { ChangeEvent, useState, Fragment } from "react";
import DatePicker from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker";
import myInvoicesStyles from "./myInvoices.module.css";
import { getMonth, getPrintDate } from "../../utils/utils";
import { testData } from "../../utils/user";
import { filterOptions } from "../../utils/constants";

function MyInvoices() {
  const [invoices, setInvoices] = useState(testData.invoices);
  const [visible, setVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [dateValues, setDateValues] = useState<Value>(new Date());

  const toggleVisible = () => {
    setVisible(!visible);
    setRadioValue("");
    setDateValues(new Date());
  };
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
  };
  return (
    <section className={myInvoicesStyles.section}>
      <h2 className={myInvoicesStyles.section__title}>חשבוניות ותשלומים</h2>

      <button
        type={"button"}
        onClick={toggleVisible}
        className={`${myInvoicesStyles.btn} hover`}
      >
        סינון
      </button>
      <div
        onChange={onChangeValue}
        className={`${myInvoicesStyles.select} ${
          visible ? myInvoicesStyles.select_visible : ""
        }`}
      >
        {filterOptions.map((option, index) => (
          <Fragment key={index}>
            <input
              type="radio"
              value={option.value}
              id={option.value}
              name="sort"
              className={myInvoicesStyles.radio}
              checked={radioValue === option.value}
              onChange={() => setRadioValue(option.value)}
            />
            <label htmlFor={option.value} className={myInvoicesStyles.label}>
              {option.title}
            </label>
          </Fragment>
        ))}
        {radioValue === "date" && (
          <DatePicker range value={dateValues} onChange={setDateValues} />
        )}
      </div>

      <ul className={myInvoicesStyles.invoicesList}>
        {invoices.map((i) => (
          <li key={i.id} className={myInvoicesStyles.invoiceItem}>
            <article className={myInvoicesStyles.invoice}>
              <p className={myInvoicesStyles.invoiceTitle}>חשבונית</p>
              <p className={myInvoicesStyles.date}>{getPrintDate(i.date)}</p>
              <p className={myInvoicesStyles.month}>
                {`${getMonth(i.date)} ${i.date.getFullYear()}`}
              </p>
              <p className={myInvoicesStyles.sum}>20₪</p>
            </article>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default MyInvoices;
