import Tooltip from "../tooltip/tooltip";
import React, { useState } from "react";
import backArrowButtonStyles from "./backArrowButton.module.css";

interface IBackArrowButtonProps {
  position?: string;
  text?: string;
  onClick?: () => void;
}

function BackArrowButton({
  position,
  text = "למסך הקודם",
  onClick,
}: IBackArrowButtonProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  if (!onClick) return null;

  return (
    <button
      type={"button"}
      onClick={onClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={backArrowButtonStyles.arrow}
    >
      <Tooltip isShown={showTooltip} position={position}>
        {text}
      </Tooltip>
    </button>
  );
}

export default BackArrowButton;
