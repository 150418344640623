import { TPrevencherForGuestsQuestion } from "../types/types";
import { countryOptions, genderOptions, phoneNumber } from "./constants";

export const prevencherId = "prevencherId";

export const startQuestions: TPrevencherForGuestsQuestion[] = [
  {
    messageId: "start",
    id: "start",
    userId: "prevencher",
    date: new Date(),
    message:
      "שלום אורח, אני תמיד כאן בשבילך. אני רוצה לשאול אותך כמה שאלות כדי להכיר אותך טוב יותר, אבל אם אתה צריך עזרה מהירה, אנא בחר באפשרות עזרה מהירה",
    unread: false,
    nextQuestionId: 0,
    dataType: "options",
    options: [
      { id: 0, desc: "בוא נדבר עכשיו" },
      { id: 1, desc: "אני צריך עזרה דחוף" },
    ],
  },
];
export const pocQuestions: TPrevencherForGuestsQuestion[] = [
  {
    messageId: "poc1",
    id: "poc1",
    userId: "prevencher",
    date: new Date(),
    message: "שלום, איך אני יכול לעזור לך?",
    unread: false,
    nextQuestionId: 1,
    dataType: "options",
    options: [
      { id: 0, desc: "אני אשמח לקבל עזרה." },
      { id: 1, desc: "אני רוצה לקבל המלצה לעזרה מתאימה עבורי" },
    ],
  },
];

export const urgentQuestions: TPrevencherForGuestsQuestion[] = [
  {
    messageId: "urgent1",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: `כאורח אתה יכול לקבל עזרה בטלפון או בפורום בלבד, מספר המוקד שלנו הוא ${phoneNumber}, האם תרצה להירשם כדי לקבל את כל ההזדמנויות`,
    unread: false,
    nextQuestionId: 1,
    dataType: "options",
    options: [
      { id: 0, desc: "כן", next: "signup" },
      { id: 1, desc: "לא" },
    ],
  },
  {
    messageId: "urgent2",
    id: "urgent",
    userId: "prevencher",
    date: new Date(),
    message: `אוקי, אנא בחר את האפשרות המתאימה למטה`,
    unread: false,
    nextQuestionId: -1,
    dataType: "options",
    options: [
      { id: 0, desc: "פורום", link: "/forum" },
      { id: 1, desc: `${phoneNumber}`, input: true },
    ],
  },
];
export const signupQuestions: TPrevencherForGuestsQuestion[] = [
  {
    messageId: "signup1",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה השם הפרטי שלך",
    unread: false,
    nextQuestionId: 1,
    dataType: "input",
    inputType: "text",
    inputId: "firstName",
    options: [],
  },
  {
    messageId: "signup2",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה שם המשפחה שלך",
    unread: false,
    nextQuestionId: 2,
    dataType: "input",
    inputType: "text",
    inputId: "lastName",
    options: [],
  },
  {
    messageId: "signup3",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה מספר תעודת הזהות שלך",
    unread: false,
    nextQuestionId: 3,
    dataType: "input",
    inputType: "number",
    inputId: "passport",
    options: [],
  },
  {
    messageId: "signup4",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה האימייל שלך",
    unread: false,
    nextQuestionId: 4,
    dataType: "input",
    inputType: "text",
    inputId: "email",
    options: [],
  },
  {
    messageId: "signup5",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה תאריך הלידה שלך",
    unread: false,
    nextQuestionId: 5,
    dataType: "input",
    inputType: "date",
    inputId: "birth",
    options: [],
  },
  {
    messageId: "signup6",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מה מספר הטלפון הנייד שלך",
    unread: false,
    nextQuestionId: 6,
    dataType: "input",
    inputType: "text",
    inputId: "phone",
    options: [],
  },
  {
    messageId: "signup7",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "אנא בחר את המדינה שלך",
    unread: false,
    nextQuestionId: 7,
    dataType: "options",
    options: countryOptions.map((o, index) => ({
      id: index,
      desc: o,
      value: "country",
    })),
  },
  {
    messageId: "signup8",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "אנא בחר את מגדר שלך",
    unread: false,
    nextQuestionId: 8,
    dataType: "options",
    options: genderOptions.map((o) => ({
      id: o.id,
      desc: o.name,
      value: "gender",
    })),
  },
  {
    messageId: "signup9",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message:
      "אנא הקלד את הסיסמה שלך. הגדרת סיסמה חזקה: באורך של לפחות 6 תווים אבל עדיף 8 או יותר. שילוב של אותיות, מספרים וסמלים",
    unread: false,
    nextQuestionId: 9,
    dataType: "input",
    options: [],
    inputId: "password",
    inputType: "text",
  },
  {
    messageId: "signup10",
    id: "signup",
    userId: "prevencher",
    date: new Date(),
    message: "מעולה. אני שולח את המידע שלך לשרת. אנא המתן למספר החשבון שלך",
    unread: false,
    nextQuestionId: -1,
    dataType: "options",
    options: [],
  },
];
