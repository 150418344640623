import popupStyles from "./support_success_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { useAppSelector } from "../../../services/hooks";
import { requestNumberSelector } from "../../../services/selectors/requests.selectors";

function SupportSuccessPopup({ onClose }: IPopupWithoutProps) {
  const requestNumber = useAppSelector(requestNumberSelector) || "12345";
  return (
    <article className={popupStyles.popup}>
      <div className={popupStyles.popup__content}>
        <button className={popupStyles.popup__closeBtn} onClick={onClose} />
        <h2 className={popupStyles.popup__title}>הפנייה נפתחה!</h2>
        <p className={popupStyles.popup__text}>
          פנייה {requestNumber} נשלחה בהצלחה תשובה תשלח אליך לאזור האישי או
          למייל (לפי ההגדרות שלך) עם סיום טיפול..
        </p>
        <img
          src={doctor}
          alt="תמונה של וונצ’י"
          className={popupStyles.popup__img}
        />
        <button
          className={`button hover ${popupStyles.popup__nextBtn}`}
          onClick={onClose}
        >
          אישור
        </button>
      </div>
    </article>
  );
}

export default SupportSuccessPopup;
