import React from "react";
import ReactDOM from "react-dom";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import modalStyles from "./modal.module.css";

interface IModal {
  children: JSX.Element;
  onClose: () => void;
}
const Modal = ({ children, onClose }: IModal): JSX.Element => {
  const modalRoot = document.getElementById("modals") as HTMLDivElement;

  return ReactDOM.createPortal(
    <>
      <div className={`${modalStyles.popupWindow}`}>{children}</div>
      <ModalOverlay onClick={onClose} />
    </>,
    modalRoot
  );
};

export default Modal;
