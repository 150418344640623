import React, { ReactNode, useState } from "react";
import { Category } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import mainContentLayoutStyles from "./mainContentLayout.module.css";
import Sidebar from "../sidebar/sidebar";
import { Link, useLocation } from "react-router-dom";
import Notifications from "../notifications/notifications";
import { menuTitles } from "../../utils/categories";
import { mobileWidth } from "../../utils/constants";
import ModalOverlay from "../modals/modalOverlay/ModalOverlay";

interface IMainContentLayoutProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
  children: ReactNode;
}

function MainContentLayout({
  activeCategory,
  changeCategory,
  children,
}: IMainContentLayoutProps) {
  const mobile = useMediaQuery(mobileWidth);
  const initialState = !mobile;
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(initialState);
  const [showNotifications, setShowNotifications] = useState(false);

  const isPrevencher = location.pathname === "/prevencher";
  const isForum = location.pathname === "/forum";

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mobile && openMenu && "lock-scroll"
      }`}
    >
      {mobile && openMenu && (
        <ModalOverlay onClick={() => setOpenMenu(false)} />
      )}
      <section
        className={`${mainContentLayoutStyles.mainContent} ${
          isPrevencher && mainContentLayoutStyles.mainContent_noScroll
        }`}
        onClick={hideNotifications}
      >
        <Notifications showNotifications={showNotifications} />
        <div
          className={`${mainContentLayoutStyles.header} ${
            isPrevencher && mainContentLayoutStyles.header_prevencher
          } ${isForum && mainContentLayoutStyles.header_forum}`}
        >
          {isPrevencher && (
            <Link
              to={"/home"}
              className={mainContentLayoutStyles.link}
              onClick={(e) => changeCategory(e, Category.Day)}
            >
              סגור צ’אט
            </Link>
          )}
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {isForum ? "פורום" : menuTitles[activeCategory]}
          </h1>
          <button
            className={`${mainContentLayoutStyles.mainContent__bell} ${
              (showNotifications || (isForum && !mobile) || isPrevencher) &&
              mainContentLayoutStyles.mainContent__bell_invisible
            }`}
            onClick={() => {
              setShowNotifications(!showNotifications);
            }}
          />
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        {children}
      </section>
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={activeCategory}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
    </main>
  );
}
export default MainContentLayout;
